<zui-icon-zeiss
	[class.event-active]="!!currentEvent"
	class="app-logo"
	size="xl"
	[visTooltip]="'home' | translate"
></zui-icon-zeiss>

<div class="event-container" *ngIf="!!currentEvent">
	<img class="event-img" [attr.src]="'assets/images/yearly-event/' + currentEvent.img" />
	<div class="event-greeting">{{ currentEvent.greeting }}</div>
</div>
