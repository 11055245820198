import { primaryBlueColors, secondaryColors, alternativeColors, greyScaleColors } from './color.definition.esm.js';

/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const t = window.ShadowRoot && (void 0 === window.ShadyCSS || window.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype,
  e = Symbol(),
  n = new WeakMap();
class s {
  constructor(t, n, s) {
    if (this._$cssResult$ = !0, s !== e) throw Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = t, this.t = n;
  }
  get styleSheet() {
    let e = this.o;
    const s = this.t;
    if (t && void 0 === e) {
      const t = void 0 !== s && 1 === s.length;
      t && (e = n.get(s)), void 0 === e && ((this.o = e = new CSSStyleSheet()).replaceSync(this.cssText), t && n.set(s, e));
    }
    return e;
  }
  toString() {
    return this.cssText;
  }
}
const o = t => new s("string" == typeof t ? t : t + "", void 0, e),
  r = (t, ...n) => {
    const o = 1 === t.length ? t[0] : n.reduce((e, n, s) => e + (t => {
      if (!0 === t._$cssResult$) return t.cssText;
      if ("number" == typeof t) return t;
      throw Error("Value passed to 'css' function must be a 'css' function result: " + t + ". Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.");
    })(n) + t[s + 1], t[0]);
    return new s(o, t, e);
  },
  i = (e, n) => {
    t ? e.adoptedStyleSheets = n.map(t => t instanceof CSSStyleSheet ? t : t.styleSheet) : n.forEach(t => {
      const n = document.createElement("style"),
        s = window.litNonce;
      void 0 !== s && n.setAttribute("nonce", s), n.textContent = t.cssText, e.appendChild(n);
    });
  },
  S = t ? t => t : t => t instanceof CSSStyleSheet ? (t => {
    let e = "";
    for (const n of t.cssRules) e += n.cssText;
    return o(e);
  })(t) : t;

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var s$1;
const e$1 = window.trustedTypes,
  r$1 = e$1 ? e$1.emptyScript : "",
  h = window.reactiveElementPolyfillSupport,
  o$1 = {
    toAttribute(t, i) {
      switch (i) {
        case Boolean:
          t = t ? r$1 : null;
          break;
        case Object:
        case Array:
          t = null == t ? t : JSON.stringify(t);
      }
      return t;
    },
    fromAttribute(t, i) {
      let s = t;
      switch (i) {
        case Boolean:
          s = null !== t;
          break;
        case Number:
          s = null === t ? null : Number(t);
          break;
        case Object:
        case Array:
          try {
            s = JSON.parse(t);
          } catch (t) {
            s = null;
          }
      }
      return s;
    }
  },
  n$1 = (t, i) => i !== t && (i == i || t == t),
  l = {
    attribute: !0,
    type: String,
    converter: o$1,
    reflect: !1,
    hasChanged: n$1
  };
class a extends HTMLElement {
  constructor() {
    super(), this._$Ei = new Map(), this.isUpdatePending = !1, this.hasUpdated = !1, this._$El = null, this.u();
  }
  static addInitializer(t) {
    var i;
    null !== (i = this.h) && void 0 !== i || (this.h = []), this.h.push(t);
  }
  static get observedAttributes() {
    this.finalize();
    const t = [];
    return this.elementProperties.forEach((i, s) => {
      const e = this._$Ep(s, i);
      void 0 !== e && (this._$Ev.set(e, s), t.push(e));
    }), t;
  }
  static createProperty(t, i = l) {
    if (i.state && (i.attribute = !1), this.finalize(), this.elementProperties.set(t, i), !i.noAccessor && !this.prototype.hasOwnProperty(t)) {
      const s = "symbol" == typeof t ? Symbol() : "__" + t,
        e = this.getPropertyDescriptor(t, s, i);
      void 0 !== e && Object.defineProperty(this.prototype, t, e);
    }
  }
  static getPropertyDescriptor(t, i, s) {
    return {
      get() {
        return this[i];
      },
      set(e) {
        const r = this[t];
        this[i] = e, this.requestUpdate(t, r, s);
      },
      configurable: !0,
      enumerable: !0
    };
  }
  static getPropertyOptions(t) {
    return this.elementProperties.get(t) || l;
  }
  static finalize() {
    if (this.hasOwnProperty("finalized")) return !1;
    this.finalized = !0;
    const t = Object.getPrototypeOf(this);
    if (t.finalize(), this.elementProperties = new Map(t.elementProperties), this._$Ev = new Map(), this.hasOwnProperty("properties")) {
      const t = this.properties,
        i = [...Object.getOwnPropertyNames(t), ...Object.getOwnPropertySymbols(t)];
      for (const s of i) this.createProperty(s, t[s]);
    }
    return this.elementStyles = this.finalizeStyles(this.styles), !0;
  }
  static finalizeStyles(i) {
    const s = [];
    if (Array.isArray(i)) {
      const e = new Set(i.flat(1 / 0).reverse());
      for (const i of e) s.unshift(S(i));
    } else void 0 !== i && s.push(S(i));
    return s;
  }
  static _$Ep(t, i) {
    const s = i.attribute;
    return !1 === s ? void 0 : "string" == typeof s ? s : "string" == typeof t ? t.toLowerCase() : void 0;
  }
  u() {
    var t;
    this._$E_ = new Promise(t => this.enableUpdating = t), this._$AL = new Map(), this._$Eg(), this.requestUpdate(), null === (t = this.constructor.h) || void 0 === t || t.forEach(t => t(this));
  }
  addController(t) {
    var i, s;
    (null !== (i = this._$ES) && void 0 !== i ? i : this._$ES = []).push(t), void 0 !== this.renderRoot && this.isConnected && (null === (s = t.hostConnected) || void 0 === s || s.call(t));
  }
  removeController(t) {
    var i;
    null === (i = this._$ES) || void 0 === i || i.splice(this._$ES.indexOf(t) >>> 0, 1);
  }
  _$Eg() {
    this.constructor.elementProperties.forEach((t, i) => {
      this.hasOwnProperty(i) && (this._$Ei.set(i, this[i]), delete this[i]);
    });
  }
  createRenderRoot() {
    var t;
    const s = null !== (t = this.shadowRoot) && void 0 !== t ? t : this.attachShadow(this.constructor.shadowRootOptions);
    return i(s, this.constructor.elementStyles), s;
  }
  connectedCallback() {
    var t;
    void 0 === this.renderRoot && (this.renderRoot = this.createRenderRoot()), this.enableUpdating(!0), null === (t = this._$ES) || void 0 === t || t.forEach(t => {
      var i;
      return null === (i = t.hostConnected) || void 0 === i ? void 0 : i.call(t);
    });
  }
  enableUpdating(t) {}
  disconnectedCallback() {
    var t;
    null === (t = this._$ES) || void 0 === t || t.forEach(t => {
      var i;
      return null === (i = t.hostDisconnected) || void 0 === i ? void 0 : i.call(t);
    });
  }
  attributeChangedCallback(t, i, s) {
    this._$AK(t, s);
  }
  _$EO(t, i, s = l) {
    var e, r;
    const h = this.constructor._$Ep(t, s);
    if (void 0 !== h && !0 === s.reflect) {
      const n = (null !== (r = null === (e = s.converter) || void 0 === e ? void 0 : e.toAttribute) && void 0 !== r ? r : o$1.toAttribute)(i, s.type);
      this._$El = t, null == n ? this.removeAttribute(h) : this.setAttribute(h, n), this._$El = null;
    }
  }
  _$AK(t, i) {
    var s, e;
    const r = this.constructor,
      h = r._$Ev.get(t);
    if (void 0 !== h && this._$El !== h) {
      const t = r.getPropertyOptions(h),
        n = t.converter,
        l = null !== (e = null !== (s = null == n ? void 0 : n.fromAttribute) && void 0 !== s ? s : "function" == typeof n ? n : null) && void 0 !== e ? e : o$1.fromAttribute;
      this._$El = h, this[h] = l(i, t.type), this._$El = null;
    }
  }
  requestUpdate(t, i, s) {
    let e = !0;
    void 0 !== t && (((s = s || this.constructor.getPropertyOptions(t)).hasChanged || n$1)(this[t], i) ? (this._$AL.has(t) || this._$AL.set(t, i), !0 === s.reflect && this._$El !== t && (void 0 === this._$EC && (this._$EC = new Map()), this._$EC.set(t, s))) : e = !1), !this.isUpdatePending && e && (this._$E_ = this._$Ej());
  }
  async _$Ej() {
    this.isUpdatePending = !0;
    try {
      await this._$E_;
    } catch (t) {
      Promise.reject(t);
    }
    const t = this.scheduleUpdate();
    return null != t && (await t), !this.isUpdatePending;
  }
  scheduleUpdate() {
    return this.performUpdate();
  }
  performUpdate() {
    var t;
    if (!this.isUpdatePending) return;
    this.hasUpdated, this._$Ei && (this._$Ei.forEach((t, i) => this[i] = t), this._$Ei = void 0);
    let i = !1;
    const s = this._$AL;
    try {
      i = this.shouldUpdate(s), i ? (this.willUpdate(s), null === (t = this._$ES) || void 0 === t || t.forEach(t => {
        var i;
        return null === (i = t.hostUpdate) || void 0 === i ? void 0 : i.call(t);
      }), this.update(s)) : this._$Ek();
    } catch (t) {
      throw i = !1, this._$Ek(), t;
    }
    i && this._$AE(s);
  }
  willUpdate(t) {}
  _$AE(t) {
    var i;
    null === (i = this._$ES) || void 0 === i || i.forEach(t => {
      var i;
      return null === (i = t.hostUpdated) || void 0 === i ? void 0 : i.call(t);
    }), this.hasUpdated || (this.hasUpdated = !0, this.firstUpdated(t)), this.updated(t);
  }
  _$Ek() {
    this._$AL = new Map(), this.isUpdatePending = !1;
  }
  get updateComplete() {
    return this.getUpdateComplete();
  }
  getUpdateComplete() {
    return this._$E_;
  }
  shouldUpdate(t) {
    return !0;
  }
  update(t) {
    void 0 !== this._$EC && (this._$EC.forEach((t, i) => this._$EO(i, this[i], t)), this._$EC = void 0), this._$Ek();
  }
  updated(t) {}
  firstUpdated(t) {}
}
a.finalized = !0, a.elementProperties = new Map(), a.elementStyles = [], a.shadowRootOptions = {
  mode: "open"
}, null == h || h({
  ReactiveElement: a
}), (null !== (s$1 = globalThis.reactiveElementVersions) && void 0 !== s$1 ? s$1 : globalThis.reactiveElementVersions = []).push("1.3.3");

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var t$1;
const i$1 = globalThis.trustedTypes,
  s$2 = i$1 ? i$1.createPolicy("lit-html", {
    createHTML: t => t
  }) : void 0,
  e$2 = `lit$${(Math.random() + "").slice(9)}$`,
  o$2 = "?" + e$2,
  n$2 = `<${o$2}>`,
  l$1 = document,
  h$1 = (t = "") => l$1.createComment(t),
  r$2 = t => null === t || "object" != typeof t && "function" != typeof t,
  d = Array.isArray,
  u = t => {
    var i;
    return d(t) || "function" == typeof (null === (i = t) || void 0 === i ? void 0 : i[Symbol.iterator]);
  },
  c = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g,
  v = /-->/g,
  a$1 = />/g,
  f = />|[ 	\n\r](?:([^\s"'>=/]+)([ 	\n\r]*=[ 	\n\r]*(?:[^ 	\n\r"'`<>=]|("|')|))|$)/g,
  _ = /'/g,
  m = /"/g,
  g = /^(?:script|style|textarea|title)$/i,
  p = t => (i, ...s) => ({
    _$litType$: t,
    strings: i,
    values: s
  }),
  $ = p(1),
  b = Symbol.for("lit-noChange"),
  w = Symbol.for("lit-nothing"),
  T = new WeakMap(),
  x = (t, i, s) => {
    var e, o;
    const n = null !== (e = null == s ? void 0 : s.renderBefore) && void 0 !== e ? e : i;
    let l = n._$litPart$;
    if (void 0 === l) {
      const t = null !== (o = null == s ? void 0 : s.renderBefore) && void 0 !== o ? o : null;
      n._$litPart$ = l = new N(i.insertBefore(h$1(), t), t, void 0, null != s ? s : {});
    }
    return l._$AI(t), l;
  },
  A = l$1.createTreeWalker(l$1, 129, null, !1),
  C = (t, i) => {
    const o = t.length - 1,
      l = [];
    let h,
      r = 2 === i ? "<svg>" : "",
      d = c;
    for (let i = 0; i < o; i++) {
      const s = t[i];
      let o,
        u,
        p = -1,
        $ = 0;
      for (; $ < s.length && (d.lastIndex = $, u = d.exec(s), null !== u);) $ = d.lastIndex, d === c ? "!--" === u[1] ? d = v : void 0 !== u[1] ? d = a$1 : void 0 !== u[2] ? (g.test(u[2]) && (h = RegExp("</" + u[2], "g")), d = f) : void 0 !== u[3] && (d = f) : d === f ? ">" === u[0] ? (d = null != h ? h : c, p = -1) : void 0 === u[1] ? p = -2 : (p = d.lastIndex - u[2].length, o = u[1], d = void 0 === u[3] ? f : '"' === u[3] ? m : _) : d === m || d === _ ? d = f : d === v || d === a$1 ? d = c : (d = f, h = void 0);
      const y = d === f && t[i + 1].startsWith("/>") ? " " : "";
      r += d === c ? s + n$2 : p >= 0 ? (l.push(o), s.slice(0, p) + "$lit$" + s.slice(p) + e$2 + y) : s + e$2 + (-2 === p ? (l.push(void 0), i) : y);
    }
    const u = r + (t[o] || "<?>") + (2 === i ? "</svg>" : "");
    if (!Array.isArray(t) || !t.hasOwnProperty("raw")) throw Error("invalid template strings array");
    return [void 0 !== s$2 ? s$2.createHTML(u) : u, l];
  };
class E {
  constructor({
    strings: t,
    _$litType$: s
  }, n) {
    let l;
    this.parts = [];
    let r = 0,
      d = 0;
    const u = t.length - 1,
      c = this.parts,
      [v, a] = C(t, s);
    if (this.el = E.createElement(v, n), A.currentNode = this.el.content, 2 === s) {
      const t = this.el.content,
        i = t.firstChild;
      i.remove(), t.append(...i.childNodes);
    }
    for (; null !== (l = A.nextNode()) && c.length < u;) {
      if (1 === l.nodeType) {
        if (l.hasAttributes()) {
          const t = [];
          for (const i of l.getAttributeNames()) if (i.endsWith("$lit$") || i.startsWith(e$2)) {
            const s = a[d++];
            if (t.push(i), void 0 !== s) {
              const t = l.getAttribute(s.toLowerCase() + "$lit$").split(e$2),
                i = /([.?@])?(.*)/.exec(s);
              c.push({
                type: 1,
                index: r,
                name: i[2],
                strings: t,
                ctor: "." === i[1] ? M : "?" === i[1] ? H : "@" === i[1] ? I : S$1
              });
            } else c.push({
              type: 6,
              index: r
            });
          }
          for (const i of t) l.removeAttribute(i);
        }
        if (g.test(l.tagName)) {
          const t = l.textContent.split(e$2),
            s = t.length - 1;
          if (s > 0) {
            l.textContent = i$1 ? i$1.emptyScript : "";
            for (let i = 0; i < s; i++) l.append(t[i], h$1()), A.nextNode(), c.push({
              type: 2,
              index: ++r
            });
            l.append(t[s], h$1());
          }
        }
      } else if (8 === l.nodeType) if (l.data === o$2) c.push({
        type: 2,
        index: r
      });else {
        let t = -1;
        for (; -1 !== (t = l.data.indexOf(e$2, t + 1));) c.push({
          type: 7,
          index: r
        }), t += e$2.length - 1;
      }
      r++;
    }
  }
  static createElement(t, i) {
    const s = l$1.createElement("template");
    return s.innerHTML = t, s;
  }
}
function P(t, i, s = t, e) {
  var o, n, l, h;
  if (i === b) return i;
  let d = void 0 !== e ? null === (o = s._$Cl) || void 0 === o ? void 0 : o[e] : s._$Cu;
  const u = r$2(i) ? void 0 : i._$litDirective$;
  return (null == d ? void 0 : d.constructor) !== u && (null === (n = null == d ? void 0 : d._$AO) || void 0 === n || n.call(d, !1), void 0 === u ? d = void 0 : (d = new u(t), d._$AT(t, s, e)), void 0 !== e ? (null !== (l = (h = s)._$Cl) && void 0 !== l ? l : h._$Cl = [])[e] = d : s._$Cu = d), void 0 !== d && (i = P(t, d._$AS(t, i.values), d, e)), i;
}
class V {
  constructor(t, i) {
    this.v = [], this._$AN = void 0, this._$AD = t, this._$AM = i;
  }
  get parentNode() {
    return this._$AM.parentNode;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  p(t) {
    var i;
    const {
        el: {
          content: s
        },
        parts: e
      } = this._$AD,
      o = (null !== (i = null == t ? void 0 : t.creationScope) && void 0 !== i ? i : l$1).importNode(s, !0);
    A.currentNode = o;
    let n = A.nextNode(),
      h = 0,
      r = 0,
      d = e[0];
    for (; void 0 !== d;) {
      if (h === d.index) {
        let i;
        2 === d.type ? i = new N(n, n.nextSibling, this, t) : 1 === d.type ? i = new d.ctor(n, d.name, d.strings, this, t) : 6 === d.type && (i = new L(n, this, t)), this.v.push(i), d = e[++r];
      }
      h !== (null == d ? void 0 : d.index) && (n = A.nextNode(), h++);
    }
    return o;
  }
  m(t) {
    let i = 0;
    for (const s of this.v) void 0 !== s && (void 0 !== s.strings ? (s._$AI(t, s, i), i += s.strings.length - 2) : s._$AI(t[i])), i++;
  }
}
class N {
  constructor(t, i, s, e) {
    var o;
    this.type = 2, this._$AH = w, this._$AN = void 0, this._$AA = t, this._$AB = i, this._$AM = s, this.options = e, this._$Cg = null === (o = null == e ? void 0 : e.isConnected) || void 0 === o || o;
  }
  get _$AU() {
    var t, i;
    return null !== (i = null === (t = this._$AM) || void 0 === t ? void 0 : t._$AU) && void 0 !== i ? i : this._$Cg;
  }
  get parentNode() {
    let t = this._$AA.parentNode;
    const i = this._$AM;
    return void 0 !== i && 11 === t.nodeType && (t = i.parentNode), t;
  }
  get startNode() {
    return this._$AA;
  }
  get endNode() {
    return this._$AB;
  }
  _$AI(t, i = this) {
    t = P(this, t, i), r$2(t) ? t === w || null == t || "" === t ? (this._$AH !== w && this._$AR(), this._$AH = w) : t !== this._$AH && t !== b && this.$(t) : void 0 !== t._$litType$ ? this.T(t) : void 0 !== t.nodeType ? this.k(t) : u(t) ? this.S(t) : this.$(t);
  }
  M(t, i = this._$AB) {
    return this._$AA.parentNode.insertBefore(t, i);
  }
  k(t) {
    this._$AH !== t && (this._$AR(), this._$AH = this.M(t));
  }
  $(t) {
    this._$AH !== w && r$2(this._$AH) ? this._$AA.nextSibling.data = t : this.k(l$1.createTextNode(t)), this._$AH = t;
  }
  T(t) {
    var i;
    const {
        values: s,
        _$litType$: e
      } = t,
      o = "number" == typeof e ? this._$AC(t) : (void 0 === e.el && (e.el = E.createElement(e.h, this.options)), e);
    if ((null === (i = this._$AH) || void 0 === i ? void 0 : i._$AD) === o) this._$AH.m(s);else {
      const t = new V(o, this),
        i = t.p(this.options);
      t.m(s), this.k(i), this._$AH = t;
    }
  }
  _$AC(t) {
    let i = T.get(t.strings);
    return void 0 === i && T.set(t.strings, i = new E(t)), i;
  }
  S(t) {
    d(this._$AH) || (this._$AH = [], this._$AR());
    const i = this._$AH;
    let s,
      e = 0;
    for (const o of t) e === i.length ? i.push(s = new N(this.M(h$1()), this.M(h$1()), this, this.options)) : s = i[e], s._$AI(o), e++;
    e < i.length && (this._$AR(s && s._$AB.nextSibling, e), i.length = e);
  }
  _$AR(t = this._$AA.nextSibling, i) {
    var s;
    for (null === (s = this._$AP) || void 0 === s || s.call(this, !1, !0, i); t && t !== this._$AB;) {
      const i = t.nextSibling;
      t.remove(), t = i;
    }
  }
  setConnected(t) {
    var i;
    void 0 === this._$AM && (this._$Cg = t, null === (i = this._$AP) || void 0 === i || i.call(this, t));
  }
}
class S$1 {
  constructor(t, i, s, e, o) {
    this.type = 1, this._$AH = w, this._$AN = void 0, this.element = t, this.name = i, this._$AM = e, this.options = o, s.length > 2 || "" !== s[0] || "" !== s[1] ? (this._$AH = Array(s.length - 1).fill(new String()), this.strings = s) : this._$AH = w;
  }
  get tagName() {
    return this.element.tagName;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t, i = this, s, e) {
    const o = this.strings;
    let n = !1;
    if (void 0 === o) t = P(this, t, i, 0), n = !r$2(t) || t !== this._$AH && t !== b, n && (this._$AH = t);else {
      const e = t;
      let l, h;
      for (t = o[0], l = 0; l < o.length - 1; l++) h = P(this, e[s + l], i, l), h === b && (h = this._$AH[l]), n || (n = !r$2(h) || h !== this._$AH[l]), h === w ? t = w : t !== w && (t += (null != h ? h : "") + o[l + 1]), this._$AH[l] = h;
    }
    n && !e && this.C(t);
  }
  C(t) {
    t === w ? this.element.removeAttribute(this.name) : this.element.setAttribute(this.name, null != t ? t : "");
  }
}
class M extends S$1 {
  constructor() {
    super(...arguments), this.type = 3;
  }
  C(t) {
    this.element[this.name] = t === w ? void 0 : t;
  }
}
const k = i$1 ? i$1.emptyScript : "";
class H extends S$1 {
  constructor() {
    super(...arguments), this.type = 4;
  }
  C(t) {
    t && t !== w ? this.element.setAttribute(this.name, k) : this.element.removeAttribute(this.name);
  }
}
class I extends S$1 {
  constructor(t, i, s, e, o) {
    super(t, i, s, e, o), this.type = 5;
  }
  _$AI(t, i = this) {
    var s;
    if ((t = null !== (s = P(this, t, i, 0)) && void 0 !== s ? s : w) === b) return;
    const e = this._$AH,
      o = t === w && e !== w || t.capture !== e.capture || t.once !== e.once || t.passive !== e.passive,
      n = t !== w && (e === w || o);
    o && this.element.removeEventListener(this.name, this, e), n && this.element.addEventListener(this.name, this, t), this._$AH = t;
  }
  handleEvent(t) {
    var i, s;
    "function" == typeof this._$AH ? this._$AH.call(null !== (s = null === (i = this.options) || void 0 === i ? void 0 : i.host) && void 0 !== s ? s : this.element, t) : this._$AH.handleEvent(t);
  }
}
class L {
  constructor(t, i, s) {
    this.element = t, this.type = 6, this._$AN = void 0, this._$AM = i, this.options = s;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t) {
    P(this, t);
  }
}
const z = window.litHtmlPolyfillSupport;
null == z || z(E, N), (null !== (t$1 = globalThis.litHtmlVersions) && void 0 !== t$1 ? t$1 : globalThis.litHtmlVersions = []).push("2.2.6");

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var l$2, o$3;
class s$3 extends a {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this._$Do = void 0;
  }
  createRenderRoot() {
    var t, e;
    const i = super.createRenderRoot();
    return null !== (t = (e = this.renderOptions).renderBefore) && void 0 !== t || (e.renderBefore = i.firstChild), i;
  }
  update(t) {
    const i = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t), this._$Do = x(i, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    var t;
    super.connectedCallback(), null === (t = this._$Do) || void 0 === t || t.setConnected(!0);
  }
  disconnectedCallback() {
    var t;
    super.disconnectedCallback(), null === (t = this._$Do) || void 0 === t || t.setConnected(!1);
  }
  render() {
    return b;
  }
}
s$3.finalized = !0, s$3._$litElement$ = !0, null === (l$2 = globalThis.litElementHydrateSupport) || void 0 === l$2 || l$2.call(globalThis, {
  LitElement: s$3
});
const n$3 = globalThis.litElementPolyfillSupport;
null == n$3 || n$3({
  LitElement: s$3
});
(null !== (o$3 = globalThis.litElementVersions) && void 0 !== o$3 ? o$3 : globalThis.litElementVersions = []).push("3.2.1");
const defineColor = (name, hex, suffix = 'rgb') => {
  const [_, r, g, b] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
  return o(`
    --${name}-${suffix}: ${rgb.join(', ')};
    --${name}: ${hex};
  `);
};
const mapColors = colors => o(Object.entries(colors).map(([name, color]) => defineColor(name, color)).join(''));
const colors = r`
  :root {
    /* Black */
    ${defineColor('BK', '#000000')}

    /* Greyscale */
    ${mapColors(greyScaleColors)}

    /* Primary Blue */
    ${mapColors(primaryBlueColors)}

    /* Secondary... */
    ${mapColors(secondaryColors)}

    /* Alternative Colors */
    ${mapColors(alternativeColors)}
  }
`;
const icons = r`
  :root {
    --zui-icon-size-8: 8px;
    --zui-icon-size-12: 12px;
    --zui-icon-size-16: 16px;
    --zui-icon-size-24: 24px;
    --zui-icon-size-32: 32px;
    --zui-icon-size-40: 40px;
    --zui-icon-size-48: 48px;
    --zui-icon-size-64: 64px;
    --zui-icon-size-72: 72px;
    --zui-icon-size-80: 80px;
  }
`;
const layout = r`
  :root {
    /* Grid Unit */
    --zui-gu: 8px;

    /* Spacing */
    --zui-space-extra-small: calc(var(--zui-gu) * 0.75);
    --zui-space-small: var(--zui-gu);
    --zui-space-medium: calc(var(--zui-gu) * 2);
    --zui-space-large: calc(var(--zui-gu) * 4);
  }
`;
const visualElements = r`
  :root {
    /* Disabled */
    --zui-disabled-opacity: 0.5;
    --zui-selection-opacity: 0.3;

    /* Line and Border Strengths */
    --zui-line-strength-thin: 1px;
    --zui-line-strength-medium: calc(var(--zui-gu) / 4);
    --zui-line-strength-thick: calc(var(--zui-gu) / 2);

    /* Border Radius */
    --zui-border-radius-default: 3px;

    /* Shadows */
    --zui-box-shadow-default: 0px 3px 8px var(--zui-color-box-shadow-default);
    --zui-box-shadow-large: 0px 3px 12px var(--zui-color-box-shadow-default);
    --zui-box-shadow-large-inverted: 0px -3px 12px var(--zui-color-box-shadow-default);
  }
`;
const commonStyles = r`
  * {
    box-sizing: border-box;
  }

  [zui-element]::selection,
  [zui-element] ::selection {
    background-color: var(--zui-color-selection-background);
  }
`;
const segoeUIFontFaces = r`
  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Light');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semilight');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semibold');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Bold');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Light Italic');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semilight Italic');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Italic');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semibold Italic');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Bold Italic');
    font-weight: 700;
    font-style: italic;
  }
`;
const fontFaces = fontDirectoryURL => {
  const fontDirectory = o(fontDirectoryURL);
  return r`
    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Light'), local('NotoSans-Light'),
        url('${fontDirectory}/NotoSans-Light.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Regular'), local('NotoSans-Regular'),
        url('${fontDirectory}/NotoSans-Regular.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Regular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans SemiBold'), local('NotoSans-SemiBold'),
        url('${fontDirectory}/NotoSans-SemiBold.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-SemiBold.woff') format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Bold'), local('NotoSans-Bold'), url('${fontDirectory}/NotoSans-Bold.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Bold.woff') format('woff');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Light Italic'), local('NotoSans-LightItalic'),
        url('${fontDirectory}/NotoSans-LightItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-LightItalic.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Italic'), local('NotoSans-Italic'),
        url('${fontDirectory}/NotoSans-Italic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-Italic.woff') format('woff');
      font-weight: 400;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans SemiBold Italic'), local('NotoSans-SemiBoldItalic'),
        url('${fontDirectory}/NotoSans-SemiBoldItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-SemiBoldItalic.woff') format('woff');
      font-weight: 600;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
        url('${fontDirectory}/NotoSans-BoldItalic.woff2') format('woff2'),
        url('${fontDirectory}/NotoSans-BoldItalic.woff') format('woff');
      font-weight: 700;
      font-style: italic;
      font-display: swap;
    }
  `;
};
const fallbackFont = r`sans-serif`;
const fontStyles = r`
  :root {
    --zui-typography-body: var(--zui-body-font-style) var(--zui-body-font-weight) var(--zui-body-font-size) /
        var(--zui-body-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h0: var(--zui-h0-font-style) var(--zui-h0-font-weight) var(--zui-h0-font-size) /
        var(--zui-h0-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h1: var(--zui-h1-font-style) var(--zui-h1-font-weight) var(--zui-h1-font-size) /
        var(--zui-h1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h2: var(--zui-h2-font-style) var(--zui-h2-font-weight) var(--zui-h2-font-size) /
        var(--zui-h2-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h3: var(--zui-h3-font-style) var(--zui-h3-font-weight) var(--zui-h3-font-size) /
        var(--zui-h3-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-h4: var(--zui-h4-font-style) var(--zui-h4-font-weight) var(--zui-h4-font-size) /
        var(--zui-h4-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-subtitle1: var(--zui-subtitle1-font-style) var(--zui-subtitle1-font-weight)
        var(--zui-subtitle1-font-size) / var(--zui-subtitle1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-subtitle2: var(--zui-subtitle2-font-style) var(--zui-subtitle2-font-weight)
        var(--zui-subtitle2-font-size) / var(--zui-subtitle2-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-caption: var(--zui-caption-font-style) var(--zui-caption-font-weight) var(--zui-caption-font-size) /
        var(--zui-caption-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-label1: var(--zui-label1-font-style) var(--zui-label1-font-weight) var(--zui-label1-font-size) /
        var(--zui-label1-line-height) var(--zui-font-family),
      ${fallbackFont};

    --zui-typography-label2: var(--zui-label2-font-style) var(--zui-label2-font-weight) var(--zui-label2-font-size) /
        var(--zui-label2-line-height) var(--zui-font-family),
      ${fallbackFont};
  }
`;
const segoeSettings = r`
  :root {
    --zui-font-family: 'Segoe UI';

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 200;
    --zui-h0-font-size: 48px;
    --zui-h0-line-height: 1.5;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 200;
    --zui-h1-font-size: 32px;
    --zui-h1-line-height: 1.5;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 24px;
    --zui-h2-line-height: 1.5;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 20px;
    --zui-h3-line-height: 1.4;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 400;
    --zui-h4-font-size: 16px;
    --zui-h4-line-height: 1.5;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 600;
    --zui-subtitle1-font-size: 14px;
    --zui-subtitle1-line-height: 1.42857143;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 12px;
    --zui-subtitle2-line-height: 1.33333333;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 14px;
    --zui-body-line-height: 1.42857143;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 12px;
    --zui-caption-line-height: 1.33333333;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 600;
    --zui-label1-font-size: 12px;
    --zui-label1-line-height: 1.33333333;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 10px;
    --zui-label2-line-height: 1.2;
  }
`;
const notoLatinSettings = r`
  :root {
    --zui-font-family: 'Noto Sans';

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 300;
    --zui-h0-font-size: 46px;
    --zui-h0-line-height: 1.56521739;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 300;
    --zui-h1-font-size: 31px;
    --zui-h1-line-height: 1.5483871;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 22px;
    --zui-h2-line-height: 1.63636364;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 19px;
    --zui-h3-line-height: 1.47368421;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 400;
    --zui-h4-font-size: 15px;
    --zui-h4-line-height: 1.6;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 600;
    --zui-subtitle1-font-size: 13px;
    --zui-subtitle1-line-height: 1.53846154;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 11px;
    --zui-subtitle2-line-height: 1.45454545;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 13px;
    --zui-body-line-height: 1.53846154;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 11px;
    --zui-caption-line-height: 1.45454545;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 600;
    --zui-label1-font-size: 11px;
    --zui-label1-line-height: 1.45454545;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 10px;
    --zui-label2-line-height: 1.2;
  }
`;
const notoCJKSettings = r`
  :root {
    /* --zui-font-family would be set at typefaceManager */

    --zui-h0-font-style: normal;
    --zui-h0-font-weight: 300;
    --zui-h0-font-size: 46px;
    --zui-h0-line-height: 1.17391304;

    --zui-h1-font-style: normal;
    --zui-h1-font-weight: 300;
    --zui-h1-font-size: 36px;
    --zui-h1-line-height: 1.27777778;

    --zui-h2-font-style: normal;
    --zui-h2-font-weight: 300;
    --zui-h2-font-size: 28px;
    --zui-h2-line-height: 1.28571429;

    --zui-h3-font-style: normal;
    --zui-h3-font-weight: 300;
    --zui-h3-font-size: 21px;
    --zui-h3-line-height: 1.14285714;

    --zui-h4-font-style: normal;
    --zui-h4-font-weight: 300;
    --zui-h4-font-size: 18px;
    --zui-h4-line-height: 1.11111111;

    --zui-subtitle1-font-style: normal;
    --zui-subtitle1-font-weight: 700;
    --zui-subtitle1-font-size: 16px;
    --zui-subtitle1-line-height: 1.125;

    --zui-subtitle2-font-style: normal;
    --zui-subtitle2-font-weight: 700;
    --zui-subtitle2-font-size: 16px;
    --zui-subtitle2-line-height: 1;

    --zui-body-font-style: normal;
    --zui-body-font-weight: 400;
    --zui-body-font-size: 16px;
    --zui-body-line-height: 1;

    --zui-caption-font-style: normal;
    --zui-caption-font-weight: 400;
    --zui-caption-font-size: 14px;
    --zui-caption-line-height: 1;

    --zui-label1-font-style: normal;
    --zui-label1-font-weight: 700;
    --zui-label1-font-size: 15px;
    --zui-label1-line-height: 0.93333333;

    --zui-label2-font-style: normal;
    --zui-label2-font-weight: 700;
    --zui-label2-font-size: 15px;
    --zui-label2-line-height: 1.06666667;
  }
`;
const isAncientEdge = () => navigator.userAgent.indexOf('Edge') > -1;
const platformCustomization = () => {
  if (navigator.platform.includes('Win')) {
    registerStyle(r`
      :root {
        --zui-internal-action-button-order: row;
        --zui-internal-action-button-alignment: flex-end;
      }
    `);
  } else {
    registerStyle(r`
      :root {
        --zui-internal-action-button-order: row-reverse;
        --zui-internal-action-button-alignment: flex-start;
      }
    `);
  }
};
const registerStyle = style => {
  const styleElement = document.createElement('style');
  styleElement.textContent = String(style);
  document.head.append(styleElement);
};
const registerTheme = theme => {
  platformCustomization();
  registerStyle(theme);
};
const setAppTheme = themeName => {
  document.getElementsByTagName('html')[0].setAttribute('theme', themeName);
};
const NotoSansJP = $`<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&display=swap&subset=japanese"
  rel="stylesheet"
/>`;
const NotoSansKR = $`<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,700&display=swap&subset=korean"
  rel="stylesheet"
/>`;
const NotoSansSC = $`<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+SC:300,400,700&display=swap&subset=chinese-simplified"
  rel="stylesheet"
/>`;
const NotoSansTC = $`<link
  href="https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,700&display=swap&subset=chinese-traditional"
  rel="stylesheet"
/>`;
const asianLanguages = new Map([['ja', {
  link: NotoSansJP,
  fontFamily: r`Noto Sans JP`
}], ['ko', {
  link: NotoSansKR,
  fontFamily: r`Noto Sans KR`
}], ['zh-Hans', {
  link: NotoSansSC,
  fontFamily: r`Noto Sans SC`
}], ['zh-Hant', {
  link: NotoSansTC,
  fontFamily: r`Noto Sans TC`
}]]);
const registerGoogleFonts = item => {
  document.head.append(item.getTemplateElement().content);
};
const registerAsianFonts = font => {
  registerGoogleFonts(font.link);
  registerStyle(r`
    :root {
      --zui-font-family: ${font.fontFamily};
    }
  `);
  registerStyle(notoCJKSettings);
};
const registerSegoe = () => {
  registerStyle(segoeUIFontFaces);
  registerStyle(segoeSettings);
};
const registerTypefaces = fontDirectoryURL => Promise.resolve().then(() => registerStyle(fontStyles)).then(() => asianLanguages.get(document.documentElement.lang)).then(asianFont => asianFont ? asianFont : Promise.reject(new Error())).then(registerAsianFonts).catch(() => {
  const segoeUICheckFontFace = new FontFace('Segoe UI', "local('Segoe UI')");
  return segoeUICheckFontFace.load().then(() => {
    registerSegoe();
  }).catch(() => {
    if (isAncientEdge()) {
      registerSegoe();
    }
    registerStyle(fontFaces(fontDirectoryURL));
    registerStyle(notoLatinSettings);
  });
});
const themeBase = r`
  ${commonStyles} ${colors} ${layout} ${visualElements} ${icons}
`;
export { fontFaces, fontStyles, notoLatinSettings, registerStyle, registerTheme, registerTypefaces, setAppTheme, themeBase };