import { Inject, Injectable } from '@angular/core';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import {
	LoggerServiceProvider,
	LogLevel,
	LogMessage,
	LogOptions,
	LogSession,
	SettingsServiceProvider,
	ToastServiceProvider,
} from '@zeiss/ng-vis-common/types';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { Environment } from 'src/environments/environment';
import { App } from 'src/environments/shared';
import { LogCat } from './log-cat.enum';
import { LogPre } from './logger';
import { SETTINGS_SERVICE, TOAST_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { ApplicationInsightsService } from '../application-insights/application-insights.service';
import { NgVisAuthSettings } from '@zeiss/ng-vis-vp-auth/types';

@Injectable()
export class LoggerService implements LoggerServiceProvider {
	public Logs$: BehaviorSubject<LogMessage[]>;
	public LogSessions$: BehaviorSubject<LogSession[]>;
	private _accountId?: number;
	private readonly path = App.LocalStorage.Prefix + App.LocalStorage.Logs;
	private logs: LogMessage[] = [];

	constructor(
		@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisAuthSettings>,
		@Inject(TOAST_SERVICE) private toastService: ToastServiceProvider,
		@Inject(ApplicationInsightsService) private applicationInsightsService: ApplicationInsightsService
	) {
		this.parseLogsFromLocalStorage();
		this.Logs$ = new BehaviorSubject(this.logs);
		this.LogSessions$ = new BehaviorSubject(this.logGroupedBySession(this.logs));
		this.aiInit();
	}

	public get currentSessionId() {
		return this.settingsService.settings.SystemSessionId ?? '';
	}

	public get accountId() {
		return this._accountId;
	}

	public set accountId(value: number | undefined) {
		this._accountId = value;
		this.updateAccountId(this.accountId);
	}

	public aiInit() {
		if (!Environment.AppInsights.Activated) {
			console.debug(LogPre, `Application Insights not activated.`);
			return;
		}
		console.debug(
			LogPre,
			`Application Insights started. Instrumentation Key: ${Environment.AppInsights.InstrumentationKey}`
		);
		this.applicationInsightsService.init();
		this.applicationInsightsService.setSessionId(this.currentSessionId);
		this.updateAccountId(this.accountId);
	}

	private updateAccountId(accountId?: number) {
		if (this.applicationInsightsService.isActivated() && accountId) {
			this.applicationInsightsService.setAccountId(accountId.toString());
		}
	}

	public aiLogEvent(name: string, customProperties?: ICustomProperties, operationParentId?: string) {
		if (!this.applicationInsightsService.isActivated() || !Environment.AppInsights.Activated) {
			return;
		}
		if (operationParentId) {
			console.warn(LogPre, 'The operationParentId will be ignored - correlation is automatically set via operation_Id');
		}
		this.applicationInsightsService.getInstanceAssertive().trackEvent({ name }, customProperties);
	}

	public aiLogMetric(name: string, average: number, customProperties?: ICustomProperties, operationParentId?: string) {
		if (!this.applicationInsightsService.isActivated() || !Environment.AppInsights.Activated) {
			return;
		}
		if (operationParentId) {
			console.warn(LogPre, 'The operationParentId will be ignored - correlation is automatically set via operation_Id');
		}
		this.applicationInsightsService.getInstanceAssertive().trackMetric({ name, average }, customProperties);
	}

	public aiLogException(
		exception: Error,
		severityLevel?: number,
		customProperties?: ICustomProperties,
		operationParentId?: string
	) {
		if (!this.applicationInsightsService.isActivated() || !Environment.AppInsights.Activated) {
			return;
		}
		if (operationParentId) {
			console.warn(LogPre, 'The operationParentId will be ignored - correlation is automatically set via operation_Id');
		}

		this.applicationInsightsService
			.getInstanceAssertive()
			.trackException({ exception, severityLevel }, customProperties);
	}

	public aiLogTrace(message: string, customProperties?: ICustomProperties, operationParentId?: string) {
		if (!this.applicationInsightsService.isActivated() || !Environment.AppInsights.Activated) {
			return;
		}
		if (operationParentId) {
			console.warn(LogPre, 'The operationParentId will be ignored - correlation is automatically set via operation_Id');
		}

		this.applicationInsightsService.getInstanceAssertive().trackTrace({ message }, customProperties);
	}

	public Log(message: string, options: LogOptions = {}) {
		if (typeof message !== 'string') {
			console.error(LogPre, `Message is not of type string`, message);
		}

		if (options.correlationId) {
			console.warn(
				LogPre,
				'The correlationId will be disregarded - correlation is now automatically handled through the current operation_Id'
			);
		}

		const log: LogMessage = {
			timestamp: dayjs().toDate(),
			category: options.category ?? LogCat.app,
			level: options.level ?? LogLevel.info,
			message,
			session: this.settingsService.settings.SystemSessionId ?? '',
			correlationId: this.applicationInsightsService.isActivated()
				? this.applicationInsightsService.getOperationId()
				: undefined,
		};

		if (options.level === LogLevel.error) {
			this.aiLogException({ name: log.category, message: log.message });
		}

		if (options.level !== LogLevel.error) {
			this.aiLogEvent(log.category, {
				level: log.category,
				message: log.message,
				showedToast: options.showToast,
				url: options.url,
				urlParams: options.urlParams,
				customProperties: options.customProperties,
			});
		}

		this.addLogMessage(log);

		if (options.showToast) {
			this.toastService.create(options.category, message, options.level, {
				url: options.url,
				urlParams: options.urlParams,
				urlActionText: options.urlActionText,
				urlNewTab: options.urlNewTab,
			});
		}
	}

	public Clear() {
		this.logs = [];
		this.LogSessions$.next(this.logGroupedBySession(this.logs));
		try {
			localStorage.setItem(this.path, JSON.stringify(this.logs));
			console.debug(LogPre, `Logs cleared.`);
		} catch (error) {
			console.error(LogPre, 'Could not write logs', { error: { error } });
		}
	}

	private addLogMessage(log: LogMessage) {
		this.logs.push(log);
		this.Logs$.next(this.logs);
		this.LogSessions$.next(this.logGroupedBySession(this.logs));
		this.truncateLogs();
	}

	private truncateLogs() {
		if (this.logs.length > 500) {
			this.logs.splice(100);
			console.debug(LogPre, `Removed 100 eldest log records`);
		}
		try {
			localStorage.setItem(this.path, JSON.stringify(this.logs));
		} catch (error) {
			console.error(LogPre, 'Could not write logs', { error: { error } });
		}
	}

	private parseLogsFromLocalStorage() {
		try {
			const storage = JSON.parse(localStorage.getItem(this.path) ?? '[]');
			if (Array.isArray(storage)) {
				this.logs = storage;
				this.logs.forEach((log) => {
					log.timestamp = dayjs(log.timestamp).toDate();
				});
				console.debug(LogPre, `Logs read from local storage`, {
					logs: { ...this.logs },
				});
			} else {
				throw new Error(`Storage object is not an array: ${storage}`);
			}
		} catch (error) {
			console.error(LogPre, `Could not read logs from LocalStorage.`, error, {
				logs: { ...this.logs },
			});
			this.logs = [];
		}
	}

	private logGroupedBySession(logs: LogMessage[]) {
		const groupedLogs = groupBy(logs, (x) => x.session);
		const sessions: LogSession[] = [];

		for (const _key in groupedLogs) {
			const key = _key as string;
			if (Object.prototype.hasOwnProperty.call(groupedLogs, key)) {
				sessions.push({
					sessionId: key,
					sessionStartedAt: this.sessionStartBySessionId(key),
					logs: groupedLogs[key],
				});
			}
		}

		return sessions;
	}

	private sessionStartBySessionId(sessionId: string) {
		const logs = this.logs
			.filter((x) => x.session === sessionId)
			.sort((a, b) => (dayjs(a.timestamp).isBefore(b.timestamp) ? -1 : 1));

		return logs[0]?.timestamp;
	}
}
