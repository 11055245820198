import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'vis-access-faq-dialog',
	templateUrl: './access-faq-dialog.component.html',
	standalone: true,
	imports: [CommonModule, MatDialogModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccessFaqDialogComponent {
	public faqs = FAQ_ITEMS;
	constructor(public dialogRef: MatDialogRef<AccessFaqDialogComponent>) {}
}

const FAQ_ITEMS = [
	{
		id: 1,
		question: 'Reasons why you are seeing this page',
		answers: [
			{
				content: `You don't have access - please request access via the person who shared the link with you.`,
				type: 'text',
			},
			{
				content: `You have reached the website via an incorrect bookmark link - please enter the URL manually in your browser and create a new bookmark.`,
				type: 'text',
			},
			{
				content: `You are subject to firewall restrictions - please check your access via your smartphone with activated mobile network (outside the ZEISS network) and contact your local IT support.`,
				type: 'text',
			},
		],
	},
	{
		id: 2,
		question: 'Errors inside the Vision Portal',
		answers: [
			{
				content: `In order to provide you with the best possible support when analyzing problems with the Vision Portal, please provide your technical contact with the detailed context.`,
				type: 'text',
			},
			{
				content: `To learn how to share a browser trace, please follow this official Microsoft documentation: https://learn.microsoft.com/en-us/azure/azure-portal/capture-browser-trace`,
				type: 'text',
			},
		],
	},
];
