import {
	ActivatedRouteSnapshot,
	DetachedRouteHandle,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationExtras,
	Params,
	Router,
} from '@angular/router';
import { lastValueFrom, filter } from 'rxjs';
import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';

export interface StoredRoute {
	handle: DetachedRouteHandle;
	queryParams: Params;
}

export interface AccessRouteDataPossibleValue {
	id: string;
	text?: string;
}

export function sortRoutesByHierarchy(curr: VpRoute, next: VpRoute) {
	return (next.data?.hierarchy ?? 0) - (curr.data?.hierarchy ?? 0);
}

export function GetResolvedUrl(route: ActivatedRouteSnapshot): string {
	const retVal = route.pathFromRoot
		// .filter((x) => x.url.length > 0)
		.map((v) => v.url.map((segment) => segment.toString()).join('/'))
		.join('/');
	return retVal;
}

/**
 * @description
 * A lifecycle hook that is called when the router detaches a component
 * Define a `visAfterDetach()` method to handle the query parameters.
 *
 * @usageNotes
 * The following snippet shows how a component can implement this interface to react on storing this component to the router cache.
 *
 * @example
 * ```
 * visAfterDetach() {
 *   // save some custom stuff or unsubscribe something...
 * }
 * ```
 */
export declare interface AfterDetach {
	/**
	 * A callback method that is invoked after the component has been detached (after it has been returned from cache by the Router).
	 */
	visAfterDetach(): void;
}

/**
 * @description
 * A lifecycle hook that is called when the router restores a component
 * Define a `visAfterReAttach()` method to handle the query parameters.
 *
 * @usageNotes
 * The following snippet shows how a component can implement this interface to reuse the query parameters from the last component state (when it has been left)
 *
 * @example
 * ```
 * visAfterReAttach(queryParams: Params) {
 *   this.router.navigate([], { queryParams });
 * }
 * ```
 */
export declare interface AfterReAttach {
	/**
	 * A callback method that is invoked after the component has been reattached (after it has been cached by the Router).
	 * @param queryParams The query parameters from the last component state when it was active.
	 */
	visAfterReAttach(queryParams: Params): void;
}

/**
 * @description
 * Use this function instead of `router.navigate()` to postpone your navigation AFTER potential ongoing navigations
 */
export async function navigateAfterCurrent(router: Router, commands: any[], extras?: NavigationExtras) {
	if (router.getCurrentNavigation()) {
		await lastValueFrom(
			router.events.pipe(
				filter((x) => x instanceof NavigationEnd || x instanceof NavigationCancel || x instanceof NavigationError)
			)
		);
	}

	return router.navigate(commands, extras);
}
