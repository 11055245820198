import { Inject, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TutorialService } from '@zeiss/ng-vis-tutorial';
import { AUTH_SERVICE, ROUTER_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { AuthServiceProvider, RouterServiceProvider, VpRoutes } from '@zeiss/ng-vis-vp-auth/types';
import { SplashComponent } from './areas/splash/splash.component';

import { ROUTE_CONFIG as AUTH_ROUTES } from '@zeiss/ng-vis-auth/routes';
import { CIM_ROUTES } from '@zeiss/ng-vis-cim/routes';
import { DOCUMENTATION_ROUTES } from '@zeiss/ng-vis-documentation/routes';
import { ROUTE_CONFIG as GBP_ROUTES } from '@zeiss/ng-vis-gbp/routes';
import { ROUTE_CONFIG as GSS_DESIGNER_ROUTES } from '@zeiss/ng-vis-gss/gss-designer/routes';
import { ROUTE_CONFIG as GSS_ROUTES } from '@zeiss/ng-vis-gss/gss/routes';
import { MDDB_ROUTES } from '@zeiss/ng-vis-mddb/routes';
import { OMR_ROUTES } from '@zeiss/ng-vis-omr/routes';
import { TST_ROUTES } from '@zeiss/ng-vis-tst/routes';
import { OTD_ROUTES } from '@zeiss/ng-vis-otd/routes';
import { ROUTE_CONFIG as UTILS_ROUTES } from '@zeiss/ng-vis-utils/routes';
import { VP_DEV_ROUTES as DEV_ROUTES } from '@zeiss/ng-vis-vp-dev/routes';
import { ROUTE_CONFIG as LOGS_ROUTES } from './areas/logs/routes';
import { ROUTE_CONFIG as LPP_ROUTES } from './areas/lpp/routes';
import { ROUTE_CONFIG as SETTINGS_ROUTES } from './areas/settings/routes';
import { ROUTE_CONFIG as STATUS_ROUTES } from './areas/status/routes';
import { ROUTE_CONFIG as TASKS_ROUTES } from './areas/task/routes';
import { ROUTE_CONFIG as DDP_ROUTES } from '@zeiss/ng-vis-ddp/routes';
import { ROUTE_CONFIG as SKILLMATRIX_ROUTES } from '@zeiss/ng-vis-skillmatrix/routes';
import { ROUTE_CONFIG as EVA_ROUTES } from '@zeiss/ng-vis-eva/routes';

export const ROUTE_PATHS = {
	root: { _: '' },
};

export const ROUTES: VpRoutes = [
	{
		path: ROUTE_PATHS.root._,
		component: SplashComponent,
		data: {
			cache: true,
		},
	},
	{
		...TASKS_ROUTES,
		loadChildren: () => import('src/app/areas/task/task.module').then((m) => m.TaskModule),
	},
	{
		...LOGS_ROUTES,
		loadChildren: () => import('src/app/areas/logs/logs.module').then((m) => m.LogsModule),
	},
	{
		...SETTINGS_ROUTES,
		loadChildren: () => import('src/app/areas/settings/settings.module').then((m) => m.SettingsModule),
	},
	{
		...TST_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-tst').then((m) => m.TstModule),
	},
	{
		...UTILS_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-utils').then((m) => m.UtilitiesModule),
	},
	{
		...CIM_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-cim').then((m) => m.NgVisCimModule),
	},
	{
		...DOCUMENTATION_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-documentation').then((m) => m.NgDocumentationModule),
	},
	{
		...OMR_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-omr').then((m) => m.OmrModule),
	},
	{
		...DEV_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-vp-dev').then((m) => m.NgVisVpDevModule),
	},
	{
		...GSS_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-gss').then((m) => m.NgVisGssModule),
	},
	{
		...GSS_DESIGNER_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-gss/gss-designer').then((m) => m.NgVisGssDesignerModule),
	},
	{
		...MDDB_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-mddb').then((m) => m.NgVisMddbModule),
	},
	{
		...GBP_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-gbp').then((m) => m.NgVisGbpModule),
	},
	{
		...LPP_ROUTES,
		loadChildren: () => import('./areas/lpp/lpp.module').then((m) => m.LppModule),
	},
	{
		...STATUS_ROUTES,
		loadChildren: () => import('src/app/areas/status/status.module').then((m) => m.StatusModule),
	},
	{
		...AUTH_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-auth').then((m) => m.NgVisAuthModule),
	},
	{
		...DDP_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-ddp').then((m) => m.NgVisDdpModule),
	},
	{
		...SKILLMATRIX_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-skillmatrix').then((m) => m.NgVisSkillMatrixModule),
	},
	{
		...OTD_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-otd').then((m) => m.NgVisOtdModule),
	},
	{
		...EVA_ROUTES,
		loadChildren: () => import('@zeiss/ng-vis-eva').then((m) => m.NgVisEvaModule),
	},
	{
		path: '**',
		redirectTo: ROUTE_PATHS.root._,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(ROUTES)],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(
		public tutorial: TutorialService,
		@Inject(AUTH_SERVICE) public auth: AuthServiceProvider,
		@Inject(ROUTER_SERVICE) public routerService: RouterServiceProvider
	) {
		this.auth.status.subscribe((status) => {
			if (status.processCompleted) {
				this.routerService.setRouteConfig(!status.authenticated ? [] : ROUTES).then(() => {
					if (status.authenticated) {
						this.tutorial.setApplicationAsLoaded();
					}
				});
			}
		});
	}
}
