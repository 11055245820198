<zui-ewiq-dialog state="info" style="min-width: 600px">
	<zui-ewiq-dialog-headline slot="headerText">Access FAQ</zui-ewiq-dialog-headline>
	<zui-ewiq-dialog-description>
		@for (faq of faqs; track faq.id) {
		<article style="text-align: left">
			<h3>{{ faq.question }}</h3>
			@for (answer of faq.answers; track answer) { @if (answer.type === 'text') {
			<p>{{ answer.content }}</p>
			} }
		</article>
		}
	</zui-ewiq-dialog-description>
	<zui-ewiq-dialog-buttons slot="footer">
		<zui-dialog-button (click)="dialogRef.close()" slot="primary">Close</zui-dialog-button>
	</zui-ewiq-dialog-buttons>
</zui-ewiq-dialog>
