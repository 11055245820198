import { Component, Inject } from '@angular/core';
import { parseName } from '@zeiss/ng-vis-common/operators';
import { debug } from '@zeiss/ng-vis-common/rx-operators';
import {
	AuthorizedRoutesServiceProvider,
	BREAKPOINT,
	ExtendedRouteData,
	HeaderbarPortalServiceProvider,
	SystemServiceProvider,
	VisAppColors,
	VpEnvironment,
} from '@zeiss/ng-vis-common/types';
import { TutorialService } from '@zeiss/ng-vis-tutorial';
import { TutorialTopics } from '@zeiss/ng-vis-tutorial/types';
import { map } from 'rxjs';
import { ROUTE_PATHS } from 'src/app/app-routing.module';
import { App } from 'src/environments/shared';
import { TaskApiService } from '../task/task-api.service';
import { TaskService } from '../task/task.service';
import {
	AUTHORIZED_ROUTES_SERVICE,
	ENVIRONMENT,
	HEADERBAR_PORTAL_SERVICE,
	SYSTEM_SERVICE,
} from '@zeiss/ng-vis-common/injection-tokens';
import { LanguageService } from '../language/language.service';
import { AUTH_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { AuthServiceProvider } from '@zeiss/ng-vis-vp-auth/types';

import { ROUTE_PATHS as LOGS_ROUTE_PATHS } from '../../areas/logs/routes';
import { ROUTE_PATHS as SETTINGS_ROUTE_PATHS } from '../../areas/settings/routes';
import { ROUTE_PATHS as TASKS_ROUTE_PATHS } from '../../areas/task/routes';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';

@Component({
	selector: 'vis-headerbar',
	templateUrl: './headerbar.component.html',
	styleUrls: ['./headerbar.component.scss'],
})
export class HeaderbarComponent {
	readonly LOGS_ROUTE_PATH = LOGS_ROUTE_PATHS;
	readonly ROUTE_PATH = ROUTE_PATHS;
	readonly SETTINGS_ROUTE_PATH = SETTINGS_ROUTE_PATHS;
	readonly TASKS_ROUTE_PATH = TASKS_ROUTE_PATHS;
	readonly RULE = RULE;

	readonly APP = App;
	readonly COLOR = VisAppColors;
	readonly TUTORIAL_TOPIC = TutorialTopics;

	readonly BREAKPOINT = BREAKPOINT;
	readonly parseName = parseName;
	readonly ENVIRONMENT = VpEnvironment;

	public readonly portal$;

	constructor(
		public task: TaskService,
		public tutorial: TutorialService,
		public taskApi: TaskApiService,
		public languageService: LanguageService,
		@Inject(AUTH_SERVICE) public auth: AuthServiceProvider,
		@Inject(AUTHORIZED_ROUTES_SERVICE) public routesService: AuthorizedRoutesServiceProvider,
		@Inject(HEADERBAR_PORTAL_SERVICE) public portalService: HeaderbarPortalServiceProvider,
		@Inject(SYSTEM_SERVICE) public systemService: SystemServiceProvider,
		@Inject(ENVIRONMENT) public environment: VpEnvironment
	) {
		this.portal$ = this.portalService.portal$.pipe(debug('portal$'));
	}

	get languageNotSupported$() {
		return this.routesService.breadcrumbs$.pipe(
			map((activeRouteDataStack: ExtendedRouteData[]) => {
				if (activeRouteDataStack.length < 1) {
					return false;
				}
				return (
					activeRouteDataStack?.[0].absoluteUrl != null &&
					this.languageService.supportedLanguages[activeRouteDataStack?.[0].absoluteUrl] &&
					this.languageService.supportedLanguages[activeRouteDataStack?.[0].absoluteUrl].indexOf(
						this.languageService.Language
					) === -1
				);
			})
		);
	}

	get breadcrumbs$() {
		return this.routesService.breadcrumbs$.pipe(
			map((routes: ExtendedRouteData[]) => {
				return routes.filter((route: ExtendedRouteData) => route.absoluteUrl && (route.url ?? '').length > 1);
			})
		);
	}
}
