export default {
	home: 'Home',
	signIn: 'Anmelden',
	tutorial: {
		bookmark: {
			add: 'Neues Lesezeichen',
			addSpecific: 'Setze ein Lesezeichen für {{ titlePrefix }} - {{ title }}',
			addSpecificByClick:
				'Setze ein Lesezeichen für <i>{{ titlePrefix }} - {{ title }}</i>, indem Du auf dieses Icon clickst.<br><br>Lesezeichen werden an die <b>Startseite angeheftet</b>.<br>Zur Startseite kommst Du, indem Du auf das <b>ZEISS-Logo</b> an der linken oberen Ecke klickst.',
			all: 'Alle Lesezeichen',
			bookmarks: 'Lesezeichen',
			pinnedSpecific: '<b>{{ url }}</b> findest Du jetzt direkt auf der Startseite',
			removeSpecific: 'Entferne das Lesezeichen {{ titlePrefix }} - {{ title }}',
			sectionDescription:
				'Das sind Deine Lesezeichen.<br><br>Du kannst Lesezeichen entfernen, indem Du das <b>Icon an der rechten oberen Ecke anklickst</b>.',
		},
	},
	warnings: {
		languageNotSupported:
			'Dieser Bereich unterstützt ihre bevorzugte Sprache ({{ currentLanguage }}) nicht. Anstatt dieser, wird die Standardsprache ({{ defaultLanguage }}) verwendet',
		sessionTimedOut: 'Session abgelaufen',
		sessionTimedOutDesc: 'Deine Loginsession ist abgelaufen. Bitte melde Dich erneut an.',
	},
	accessHelp: 'Probleme beim Zugriff?',
};
