import { Router } from '@angular/router';

/**
 * Builds a list of actions for the global search based on the provided routes.
 * This can then be used to navigate to the routes directly.
 * @param router instance of the Angular Router
 * @param routes reduced list of routes to build actions for
 * @returns List of actions for the global search
 */
export const buildRouteActions = (router: Router, routes: { path: string; title: string }[]) => {
	return routes.map((route) => ({
		id: `${route.path}-route`,
		title: `Go to: ${route.title}`,
		handler: () => router.navigate([route.path]),
	}));
};
