import { Injectable } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApplicationInsightsService {
	private applicationInsights?: ApplicationInsights;

	public init() {
		const angularPlugin = new AngularPlugin();
		this.applicationInsights = new ApplicationInsights({
			config: {
				instrumentationKey: Environment.AppInsights.InstrumentationKey,
				extensions: [angularPlugin],
				enableCorsCorrelation: true,
				enableRequestHeaderTracking: true,
				enableResponseHeaderTracking: true,
				enableAutoRouteTracking: true,
				distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
				// TODO: Investigate why `onunloadDisableBeacon: false` is freezing Chromium browsers when leaving the current tab / browser
				onunloadDisableBeacon: true,
			},
		});
		this.applicationInsights.loadAppInsights();
	}

	public getOperationId() {
		return this.getInstanceAssertive().context.telemetryTrace.traceID;
	}

	public setSessionId(sessionId: string) {
		this.getInstanceAssertive().context.user.accountId = sessionId;
	}

	public setAccountId(accountId: string) {
		this.getInstanceAssertive().context.user.accountId = accountId;
	}

	public isActivated() {
		return !!this.applicationInsights;
	}

	public getInstanceAssertive() {
		if (!this.applicationInsights) {
			throw new Error(
				'The Application Insights instance was requested without being initialized - has the init method been called?'
			);
		}
		return this.applicationInsights;
	}
}
