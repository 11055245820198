import { Inject, Injectable, Renderer2 } from '@angular/core';
import { ENVIRONMENT } from '@zeiss/ng-vis-common/injection-tokens';
import { VpEnvironment } from '@zeiss/ng-vis-common/types';

@Injectable({
	providedIn: 'root',
})
export class ClarityTrackingService {
	public renderer: Renderer2;
	private environmentKeys = {
		DEV: 'g5wlw0atad',
		QAS: 'g5wllr673b',
		PRD: 'g5wk3eb37j',
	};

	constructor(@Inject(ENVIRONMENT) private environment: VpEnvironment) {}

	public initClarityTracking() {
		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		const scriptText = this.buildClarityScript();
		if (!scriptText) return;
		script.text = scriptText;
		this.renderer.appendChild(document.body, script);
	}

	private buildClarityScript() {
		if (this.environment === VpEnvironment.DEV && !document.location.href.includes('portal-dev')) return;
		return `(function(c,l,a,r,i,t,y){         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);     })(window, document, "clarity", "script", "${
			this.environmentKeys[this.environment]
		}");`;
	}
}
