import { SystemLanguage } from '@zeiss/ng-vis-common/types';
import * as enGBExternal from './en-GB';
import * as esESExternal from './es-ES';
import * as deDEExternal from './de-DE';

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
export const LOGS_TRANSLATIONS: Partial<Record<SystemLanguage, { logs: any }>> = {
	[SystemLanguage['en-GB']]: { ...enGBExternal.default },
	[SystemLanguage['de-DE']]: { ...deDEExternal.default },
	[SystemLanguage['es-ES']]: { ...esESExternal.default },
};
