export default {
	tutorial: {
		bookmark: {
			add: 'Añadir un marcador',
			addSpecific: 'Añadir {{ titlePrefix }} - {{ title }} a marcadores',
			addSpecificByClick:
				'Añadir <i>{{ titlePrefix }} - {{ title }}</i> a sus marcadores haciendo clic en este icono.<br><br>Los marcadores se <b>anclan en la página de inicio</b>.<br>Podrá acceder a la página de inicio haciendo clic en el <b>icono de la aplicación</b> de la esquina superior izquierda.',
			all: 'Todos los marcadores',
			bookmarks: 'Marcadores',
			pinnedSpecific: '<b>{{ url }}</b> anclada en la página de inicio',
			removeSpecific: 'Eliminar {{ titlePrefix }} - {{ title }} de marcadores',
			sectionDescription:
				'Estos son sus marcadores.<br><br>Elimínelos haciendo clic en el <b>icono en la esquina superior derecha</b>.',
		},
		general: 'General',
	},
	accessHelp: 'Need help with access?',
};
