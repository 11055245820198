import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { App, YearlyEvent, YEARLY_EVENTS } from 'src/environments/shared';

// const testDates = {
// 	chineseNewYear2023: dayjs(Date.UTC(2023, 0, 22)),
// 	chineseNewYear2024: dayjs(Date.UTC(2024, 1, 10)),
// 	chineseNewYear2025: dayjs(Date.UTC(2025, 0, 29)),
// 	chineseNewYear2026: dayjs(Date.UTC(2026, 1, 17)),
// 	chineseNewYear2027: dayjs(Date.UTC(2027, 1, 6)),
// 	chineseNewYear2028: dayjs(Date.UTC(2028, 0, 26)),
// 	chineseNewYear2029: dayjs(Date.UTC(2029, 1, 13)),
// 	chineseNewYear2030: dayjs(Date.UTC(2030, 1, 3)),
// 	chineseNewYear2031: dayjs(Date.UTC(2031, 0, 23)),
// 	chineseNewYear2032: dayjs(Date.UTC(2032, 1, 11)),
// 	chineseNewYear2033: dayjs(Date.UTC(2033, 0, 31)),
// 	easter2024: dayjs(Date.UTC(2024, 2, 31)),
// 	ramadan2023: dayjs(Date.UTC(2023, 2, 23)),
// 	christmas2022: dayjs(Date.UTC(2022, 11, 24)),
// };

@Component({
	selector: 'vis-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss'],
})
export class EventComponent {
	appEntered = dayjs(App.AppEntered);
	// appEntered = testDates.christmas2022; // testing
	currentEvent?: YearlyEvent;

	constructor() {
		const currentEvent = YEARLY_EVENTS.filter((event) => {
			const date = dayjs(event.date);
			const start = date.subtract((event.daysBefore ?? 0) + 1, 'days');
			const end = date.add((event.daysAfter ?? 0) + 1, 'days');
			return this.appEntered.isAfter(start, 'day') && this.appEntered.isBefore(end, 'day');
		});

		if (currentEvent.length > 0) {
			setTimeout(() => (this.currentEvent = currentEvent[0]), 5000);
		}
	}
}
