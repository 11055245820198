/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const t = window.ShadowRoot && (void 0 === window.ShadyCSS || window.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype,
  e = Symbol(),
  n = new WeakMap();
class s {
  constructor(t, n, s) {
    if (this._$cssResult$ = !0, s !== e) throw Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = t, this.t = n;
  }
  get styleSheet() {
    let e = this.o;
    const s = this.t;
    if (t && void 0 === e) {
      const t = void 0 !== s && 1 === s.length;
      t && (e = n.get(s)), void 0 === e && ((this.o = e = new CSSStyleSheet()).replaceSync(this.cssText), t && n.set(s, e));
    }
    return e;
  }
  toString() {
    return this.cssText;
  }
}
const o = t => new s("string" == typeof t ? t : t + "", void 0, e),
  r = (t, ...n) => {
    const o = 1 === t.length ? t[0] : n.reduce((e, n, s) => e + (t => {
      if (!0 === t._$cssResult$) return t.cssText;
      if ("number" == typeof t) return t;
      throw Error("Value passed to 'css' function must be a 'css' function result: " + t + ". Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.");
    })(n) + t[s + 1], t[0]);
    return new s(o, t, e);
  },
  i = (e, n) => {
    t ? e.adoptedStyleSheets = n.map(t => t instanceof CSSStyleSheet ? t : t.styleSheet) : n.forEach(t => {
      const n = document.createElement("style"),
        s = window.litNonce;
      void 0 !== s && n.setAttribute("nonce", s), n.textContent = t.cssText, e.appendChild(n);
    });
  },
  S = t ? t => t : t => t instanceof CSSStyleSheet ? (t => {
    let e = "";
    for (const n of t.cssRules) e += n.cssText;
    return o(e);
  })(t) : t;

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var s$1;
const e$1 = window.trustedTypes,
  r$1 = e$1 ? e$1.emptyScript : "",
  h = window.reactiveElementPolyfillSupport,
  o$1 = {
    toAttribute(t, i) {
      switch (i) {
        case Boolean:
          t = t ? r$1 : null;
          break;
        case Object:
        case Array:
          t = null == t ? t : JSON.stringify(t);
      }
      return t;
    },
    fromAttribute(t, i) {
      let s = t;
      switch (i) {
        case Boolean:
          s = null !== t;
          break;
        case Number:
          s = null === t ? null : Number(t);
          break;
        case Object:
        case Array:
          try {
            s = JSON.parse(t);
          } catch (t) {
            s = null;
          }
      }
      return s;
    }
  },
  n$1 = (t, i) => i !== t && (i == i || t == t),
  l = {
    attribute: !0,
    type: String,
    converter: o$1,
    reflect: !1,
    hasChanged: n$1
  };
class a extends HTMLElement {
  constructor() {
    super(), this._$Ei = new Map(), this.isUpdatePending = !1, this.hasUpdated = !1, this._$El = null, this.u();
  }
  static addInitializer(t) {
    var i;
    null !== (i = this.h) && void 0 !== i || (this.h = []), this.h.push(t);
  }
  static get observedAttributes() {
    this.finalize();
    const t = [];
    return this.elementProperties.forEach((i, s) => {
      const e = this._$Ep(s, i);
      void 0 !== e && (this._$Ev.set(e, s), t.push(e));
    }), t;
  }
  static createProperty(t, i = l) {
    if (i.state && (i.attribute = !1), this.finalize(), this.elementProperties.set(t, i), !i.noAccessor && !this.prototype.hasOwnProperty(t)) {
      const s = "symbol" == typeof t ? Symbol() : "__" + t,
        e = this.getPropertyDescriptor(t, s, i);
      void 0 !== e && Object.defineProperty(this.prototype, t, e);
    }
  }
  static getPropertyDescriptor(t, i, s) {
    return {
      get() {
        return this[i];
      },
      set(e) {
        const r = this[t];
        this[i] = e, this.requestUpdate(t, r, s);
      },
      configurable: !0,
      enumerable: !0
    };
  }
  static getPropertyOptions(t) {
    return this.elementProperties.get(t) || l;
  }
  static finalize() {
    if (this.hasOwnProperty("finalized")) return !1;
    this.finalized = !0;
    const t = Object.getPrototypeOf(this);
    if (t.finalize(), this.elementProperties = new Map(t.elementProperties), this._$Ev = new Map(), this.hasOwnProperty("properties")) {
      const t = this.properties,
        i = [...Object.getOwnPropertyNames(t), ...Object.getOwnPropertySymbols(t)];
      for (const s of i) this.createProperty(s, t[s]);
    }
    return this.elementStyles = this.finalizeStyles(this.styles), !0;
  }
  static finalizeStyles(i) {
    const s = [];
    if (Array.isArray(i)) {
      const e = new Set(i.flat(1 / 0).reverse());
      for (const i of e) s.unshift(S(i));
    } else void 0 !== i && s.push(S(i));
    return s;
  }
  static _$Ep(t, i) {
    const s = i.attribute;
    return !1 === s ? void 0 : "string" == typeof s ? s : "string" == typeof t ? t.toLowerCase() : void 0;
  }
  u() {
    var t;
    this._$E_ = new Promise(t => this.enableUpdating = t), this._$AL = new Map(), this._$Eg(), this.requestUpdate(), null === (t = this.constructor.h) || void 0 === t || t.forEach(t => t(this));
  }
  addController(t) {
    var i, s;
    (null !== (i = this._$ES) && void 0 !== i ? i : this._$ES = []).push(t), void 0 !== this.renderRoot && this.isConnected && (null === (s = t.hostConnected) || void 0 === s || s.call(t));
  }
  removeController(t) {
    var i;
    null === (i = this._$ES) || void 0 === i || i.splice(this._$ES.indexOf(t) >>> 0, 1);
  }
  _$Eg() {
    this.constructor.elementProperties.forEach((t, i) => {
      this.hasOwnProperty(i) && (this._$Ei.set(i, this[i]), delete this[i]);
    });
  }
  createRenderRoot() {
    var t;
    const s = null !== (t = this.shadowRoot) && void 0 !== t ? t : this.attachShadow(this.constructor.shadowRootOptions);
    return i(s, this.constructor.elementStyles), s;
  }
  connectedCallback() {
    var t;
    void 0 === this.renderRoot && (this.renderRoot = this.createRenderRoot()), this.enableUpdating(!0), null === (t = this._$ES) || void 0 === t || t.forEach(t => {
      var i;
      return null === (i = t.hostConnected) || void 0 === i ? void 0 : i.call(t);
    });
  }
  enableUpdating(t) {}
  disconnectedCallback() {
    var t;
    null === (t = this._$ES) || void 0 === t || t.forEach(t => {
      var i;
      return null === (i = t.hostDisconnected) || void 0 === i ? void 0 : i.call(t);
    });
  }
  attributeChangedCallback(t, i, s) {
    this._$AK(t, s);
  }
  _$EO(t, i, s = l) {
    var e, r;
    const h = this.constructor._$Ep(t, s);
    if (void 0 !== h && !0 === s.reflect) {
      const n = (null !== (r = null === (e = s.converter) || void 0 === e ? void 0 : e.toAttribute) && void 0 !== r ? r : o$1.toAttribute)(i, s.type);
      this._$El = t, null == n ? this.removeAttribute(h) : this.setAttribute(h, n), this._$El = null;
    }
  }
  _$AK(t, i) {
    var s, e;
    const r = this.constructor,
      h = r._$Ev.get(t);
    if (void 0 !== h && this._$El !== h) {
      const t = r.getPropertyOptions(h),
        n = t.converter,
        l = null !== (e = null !== (s = null == n ? void 0 : n.fromAttribute) && void 0 !== s ? s : "function" == typeof n ? n : null) && void 0 !== e ? e : o$1.fromAttribute;
      this._$El = h, this[h] = l(i, t.type), this._$El = null;
    }
  }
  requestUpdate(t, i, s) {
    let e = !0;
    void 0 !== t && (((s = s || this.constructor.getPropertyOptions(t)).hasChanged || n$1)(this[t], i) ? (this._$AL.has(t) || this._$AL.set(t, i), !0 === s.reflect && this._$El !== t && (void 0 === this._$EC && (this._$EC = new Map()), this._$EC.set(t, s))) : e = !1), !this.isUpdatePending && e && (this._$E_ = this._$Ej());
  }
  async _$Ej() {
    this.isUpdatePending = !0;
    try {
      await this._$E_;
    } catch (t) {
      Promise.reject(t);
    }
    const t = this.scheduleUpdate();
    return null != t && (await t), !this.isUpdatePending;
  }
  scheduleUpdate() {
    return this.performUpdate();
  }
  performUpdate() {
    var t;
    if (!this.isUpdatePending) return;
    this.hasUpdated, this._$Ei && (this._$Ei.forEach((t, i) => this[i] = t), this._$Ei = void 0);
    let i = !1;
    const s = this._$AL;
    try {
      i = this.shouldUpdate(s), i ? (this.willUpdate(s), null === (t = this._$ES) || void 0 === t || t.forEach(t => {
        var i;
        return null === (i = t.hostUpdate) || void 0 === i ? void 0 : i.call(t);
      }), this.update(s)) : this._$Ek();
    } catch (t) {
      throw i = !1, this._$Ek(), t;
    }
    i && this._$AE(s);
  }
  willUpdate(t) {}
  _$AE(t) {
    var i;
    null === (i = this._$ES) || void 0 === i || i.forEach(t => {
      var i;
      return null === (i = t.hostUpdated) || void 0 === i ? void 0 : i.call(t);
    }), this.hasUpdated || (this.hasUpdated = !0, this.firstUpdated(t)), this.updated(t);
  }
  _$Ek() {
    this._$AL = new Map(), this.isUpdatePending = !1;
  }
  get updateComplete() {
    return this.getUpdateComplete();
  }
  getUpdateComplete() {
    return this._$E_;
  }
  shouldUpdate(t) {
    return !0;
  }
  update(t) {
    void 0 !== this._$EC && (this._$EC.forEach((t, i) => this._$EO(i, this[i], t)), this._$EC = void 0), this._$Ek();
  }
  updated(t) {}
  firstUpdated(t) {}
}
a.finalized = !0, a.elementProperties = new Map(), a.elementStyles = [], a.shadowRootOptions = {
  mode: "open"
}, null == h || h({
  ReactiveElement: a
}), (null !== (s$1 = globalThis.reactiveElementVersions) && void 0 !== s$1 ? s$1 : globalThis.reactiveElementVersions = []).push("1.3.3");

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var t$1;
const i$1 = globalThis.trustedTypes,
  s$2 = i$1 ? i$1.createPolicy("lit-html", {
    createHTML: t => t
  }) : void 0,
  e$2 = `lit$${(Math.random() + "").slice(9)}$`,
  o$2 = "?" + e$2,
  n$2 = `<${o$2}>`,
  l$1 = document,
  h$1 = (t = "") => l$1.createComment(t),
  r$2 = t => null === t || "object" != typeof t && "function" != typeof t,
  d = Array.isArray,
  u = t => {
    var i;
    return d(t) || "function" == typeof (null === (i = t) || void 0 === i ? void 0 : i[Symbol.iterator]);
  },
  c = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g,
  v = /-->/g,
  a$1 = />/g,
  f = />|[ 	\n\r](?:([^\s"'>=/]+)([ 	\n\r]*=[ 	\n\r]*(?:[^ 	\n\r"'`<>=]|("|')|))|$)/g,
  _ = /'/g,
  m = /"/g,
  g = /^(?:script|style|textarea|title)$/i,
  b = Symbol.for("lit-noChange"),
  w = Symbol.for("lit-nothing"),
  T = new WeakMap(),
  x = (t, i, s) => {
    var e, o;
    const n = null !== (e = null == s ? void 0 : s.renderBefore) && void 0 !== e ? e : i;
    let l = n._$litPart$;
    if (void 0 === l) {
      const t = null !== (o = null == s ? void 0 : s.renderBefore) && void 0 !== o ? o : null;
      n._$litPart$ = l = new N(i.insertBefore(h$1(), t), t, void 0, null != s ? s : {});
    }
    return l._$AI(t), l;
  },
  A = l$1.createTreeWalker(l$1, 129, null, !1),
  C = (t, i) => {
    const o = t.length - 1,
      l = [];
    let h,
      r = 2 === i ? "<svg>" : "",
      d = c;
    for (let i = 0; i < o; i++) {
      const s = t[i];
      let o,
        u,
        p = -1,
        $ = 0;
      for (; $ < s.length && (d.lastIndex = $, u = d.exec(s), null !== u);) $ = d.lastIndex, d === c ? "!--" === u[1] ? d = v : void 0 !== u[1] ? d = a$1 : void 0 !== u[2] ? (g.test(u[2]) && (h = RegExp("</" + u[2], "g")), d = f) : void 0 !== u[3] && (d = f) : d === f ? ">" === u[0] ? (d = null != h ? h : c, p = -1) : void 0 === u[1] ? p = -2 : (p = d.lastIndex - u[2].length, o = u[1], d = void 0 === u[3] ? f : '"' === u[3] ? m : _) : d === m || d === _ ? d = f : d === v || d === a$1 ? d = c : (d = f, h = void 0);
      const y = d === f && t[i + 1].startsWith("/>") ? " " : "";
      r += d === c ? s + n$2 : p >= 0 ? (l.push(o), s.slice(0, p) + "$lit$" + s.slice(p) + e$2 + y) : s + e$2 + (-2 === p ? (l.push(void 0), i) : y);
    }
    const u = r + (t[o] || "<?>") + (2 === i ? "</svg>" : "");
    if (!Array.isArray(t) || !t.hasOwnProperty("raw")) throw Error("invalid template strings array");
    return [void 0 !== s$2 ? s$2.createHTML(u) : u, l];
  };
class E {
  constructor({
    strings: t,
    _$litType$: s
  }, n) {
    let l;
    this.parts = [];
    let r = 0,
      d = 0;
    const u = t.length - 1,
      c = this.parts,
      [v, a] = C(t, s);
    if (this.el = E.createElement(v, n), A.currentNode = this.el.content, 2 === s) {
      const t = this.el.content,
        i = t.firstChild;
      i.remove(), t.append(...i.childNodes);
    }
    for (; null !== (l = A.nextNode()) && c.length < u;) {
      if (1 === l.nodeType) {
        if (l.hasAttributes()) {
          const t = [];
          for (const i of l.getAttributeNames()) if (i.endsWith("$lit$") || i.startsWith(e$2)) {
            const s = a[d++];
            if (t.push(i), void 0 !== s) {
              const t = l.getAttribute(s.toLowerCase() + "$lit$").split(e$2),
                i = /([.?@])?(.*)/.exec(s);
              c.push({
                type: 1,
                index: r,
                name: i[2],
                strings: t,
                ctor: "." === i[1] ? M : "?" === i[1] ? H : "@" === i[1] ? I : S$1
              });
            } else c.push({
              type: 6,
              index: r
            });
          }
          for (const i of t) l.removeAttribute(i);
        }
        if (g.test(l.tagName)) {
          const t = l.textContent.split(e$2),
            s = t.length - 1;
          if (s > 0) {
            l.textContent = i$1 ? i$1.emptyScript : "";
            for (let i = 0; i < s; i++) l.append(t[i], h$1()), A.nextNode(), c.push({
              type: 2,
              index: ++r
            });
            l.append(t[s], h$1());
          }
        }
      } else if (8 === l.nodeType) if (l.data === o$2) c.push({
        type: 2,
        index: r
      });else {
        let t = -1;
        for (; -1 !== (t = l.data.indexOf(e$2, t + 1));) c.push({
          type: 7,
          index: r
        }), t += e$2.length - 1;
      }
      r++;
    }
  }
  static createElement(t, i) {
    const s = l$1.createElement("template");
    return s.innerHTML = t, s;
  }
}
function P(t, i, s = t, e) {
  var o, n, l, h;
  if (i === b) return i;
  let d = void 0 !== e ? null === (o = s._$Cl) || void 0 === o ? void 0 : o[e] : s._$Cu;
  const u = r$2(i) ? void 0 : i._$litDirective$;
  return (null == d ? void 0 : d.constructor) !== u && (null === (n = null == d ? void 0 : d._$AO) || void 0 === n || n.call(d, !1), void 0 === u ? d = void 0 : (d = new u(t), d._$AT(t, s, e)), void 0 !== e ? (null !== (l = (h = s)._$Cl) && void 0 !== l ? l : h._$Cl = [])[e] = d : s._$Cu = d), void 0 !== d && (i = P(t, d._$AS(t, i.values), d, e)), i;
}
class V {
  constructor(t, i) {
    this.v = [], this._$AN = void 0, this._$AD = t, this._$AM = i;
  }
  get parentNode() {
    return this._$AM.parentNode;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  p(t) {
    var i;
    const {
        el: {
          content: s
        },
        parts: e
      } = this._$AD,
      o = (null !== (i = null == t ? void 0 : t.creationScope) && void 0 !== i ? i : l$1).importNode(s, !0);
    A.currentNode = o;
    let n = A.nextNode(),
      h = 0,
      r = 0,
      d = e[0];
    for (; void 0 !== d;) {
      if (h === d.index) {
        let i;
        2 === d.type ? i = new N(n, n.nextSibling, this, t) : 1 === d.type ? i = new d.ctor(n, d.name, d.strings, this, t) : 6 === d.type && (i = new L(n, this, t)), this.v.push(i), d = e[++r];
      }
      h !== (null == d ? void 0 : d.index) && (n = A.nextNode(), h++);
    }
    return o;
  }
  m(t) {
    let i = 0;
    for (const s of this.v) void 0 !== s && (void 0 !== s.strings ? (s._$AI(t, s, i), i += s.strings.length - 2) : s._$AI(t[i])), i++;
  }
}
class N {
  constructor(t, i, s, e) {
    var o;
    this.type = 2, this._$AH = w, this._$AN = void 0, this._$AA = t, this._$AB = i, this._$AM = s, this.options = e, this._$Cg = null === (o = null == e ? void 0 : e.isConnected) || void 0 === o || o;
  }
  get _$AU() {
    var t, i;
    return null !== (i = null === (t = this._$AM) || void 0 === t ? void 0 : t._$AU) && void 0 !== i ? i : this._$Cg;
  }
  get parentNode() {
    let t = this._$AA.parentNode;
    const i = this._$AM;
    return void 0 !== i && 11 === t.nodeType && (t = i.parentNode), t;
  }
  get startNode() {
    return this._$AA;
  }
  get endNode() {
    return this._$AB;
  }
  _$AI(t, i = this) {
    t = P(this, t, i), r$2(t) ? t === w || null == t || "" === t ? (this._$AH !== w && this._$AR(), this._$AH = w) : t !== this._$AH && t !== b && this.$(t) : void 0 !== t._$litType$ ? this.T(t) : void 0 !== t.nodeType ? this.k(t) : u(t) ? this.S(t) : this.$(t);
  }
  M(t, i = this._$AB) {
    return this._$AA.parentNode.insertBefore(t, i);
  }
  k(t) {
    this._$AH !== t && (this._$AR(), this._$AH = this.M(t));
  }
  $(t) {
    this._$AH !== w && r$2(this._$AH) ? this._$AA.nextSibling.data = t : this.k(l$1.createTextNode(t)), this._$AH = t;
  }
  T(t) {
    var i;
    const {
        values: s,
        _$litType$: e
      } = t,
      o = "number" == typeof e ? this._$AC(t) : (void 0 === e.el && (e.el = E.createElement(e.h, this.options)), e);
    if ((null === (i = this._$AH) || void 0 === i ? void 0 : i._$AD) === o) this._$AH.m(s);else {
      const t = new V(o, this),
        i = t.p(this.options);
      t.m(s), this.k(i), this._$AH = t;
    }
  }
  _$AC(t) {
    let i = T.get(t.strings);
    return void 0 === i && T.set(t.strings, i = new E(t)), i;
  }
  S(t) {
    d(this._$AH) || (this._$AH = [], this._$AR());
    const i = this._$AH;
    let s,
      e = 0;
    for (const o of t) e === i.length ? i.push(s = new N(this.M(h$1()), this.M(h$1()), this, this.options)) : s = i[e], s._$AI(o), e++;
    e < i.length && (this._$AR(s && s._$AB.nextSibling, e), i.length = e);
  }
  _$AR(t = this._$AA.nextSibling, i) {
    var s;
    for (null === (s = this._$AP) || void 0 === s || s.call(this, !1, !0, i); t && t !== this._$AB;) {
      const i = t.nextSibling;
      t.remove(), t = i;
    }
  }
  setConnected(t) {
    var i;
    void 0 === this._$AM && (this._$Cg = t, null === (i = this._$AP) || void 0 === i || i.call(this, t));
  }
}
class S$1 {
  constructor(t, i, s, e, o) {
    this.type = 1, this._$AH = w, this._$AN = void 0, this.element = t, this.name = i, this._$AM = e, this.options = o, s.length > 2 || "" !== s[0] || "" !== s[1] ? (this._$AH = Array(s.length - 1).fill(new String()), this.strings = s) : this._$AH = w;
  }
  get tagName() {
    return this.element.tagName;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t, i = this, s, e) {
    const o = this.strings;
    let n = !1;
    if (void 0 === o) t = P(this, t, i, 0), n = !r$2(t) || t !== this._$AH && t !== b, n && (this._$AH = t);else {
      const e = t;
      let l, h;
      for (t = o[0], l = 0; l < o.length - 1; l++) h = P(this, e[s + l], i, l), h === b && (h = this._$AH[l]), n || (n = !r$2(h) || h !== this._$AH[l]), h === w ? t = w : t !== w && (t += (null != h ? h : "") + o[l + 1]), this._$AH[l] = h;
    }
    n && !e && this.C(t);
  }
  C(t) {
    t === w ? this.element.removeAttribute(this.name) : this.element.setAttribute(this.name, null != t ? t : "");
  }
}
class M extends S$1 {
  constructor() {
    super(...arguments), this.type = 3;
  }
  C(t) {
    this.element[this.name] = t === w ? void 0 : t;
  }
}
const k = i$1 ? i$1.emptyScript : "";
class H extends S$1 {
  constructor() {
    super(...arguments), this.type = 4;
  }
  C(t) {
    t && t !== w ? this.element.setAttribute(this.name, k) : this.element.removeAttribute(this.name);
  }
}
class I extends S$1 {
  constructor(t, i, s, e, o) {
    super(t, i, s, e, o), this.type = 5;
  }
  _$AI(t, i = this) {
    var s;
    if ((t = null !== (s = P(this, t, i, 0)) && void 0 !== s ? s : w) === b) return;
    const e = this._$AH,
      o = t === w && e !== w || t.capture !== e.capture || t.once !== e.once || t.passive !== e.passive,
      n = t !== w && (e === w || o);
    o && this.element.removeEventListener(this.name, this, e), n && this.element.addEventListener(this.name, this, t), this._$AH = t;
  }
  handleEvent(t) {
    var i, s;
    "function" == typeof this._$AH ? this._$AH.call(null !== (s = null === (i = this.options) || void 0 === i ? void 0 : i.host) && void 0 !== s ? s : this.element, t) : this._$AH.handleEvent(t);
  }
}
class L {
  constructor(t, i, s) {
    this.element = t, this.type = 6, this._$AN = void 0, this._$AM = i, this.options = s;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t) {
    P(this, t);
  }
}
const z = window.litHtmlPolyfillSupport;
null == z || z(E, N), (null !== (t$1 = globalThis.litHtmlVersions) && void 0 !== t$1 ? t$1 : globalThis.litHtmlVersions = []).push("2.2.6");

/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var l$2, o$3;
class s$3 extends a {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this._$Do = void 0;
  }
  createRenderRoot() {
    var t, e;
    const i = super.createRenderRoot();
    return null !== (t = (e = this.renderOptions).renderBefore) && void 0 !== t || (e.renderBefore = i.firstChild), i;
  }
  update(t) {
    const i = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t), this._$Do = x(i, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    var t;
    super.connectedCallback(), null === (t = this._$Do) || void 0 === t || t.setConnected(!0);
  }
  disconnectedCallback() {
    var t;
    super.disconnectedCallback(), null === (t = this._$Do) || void 0 === t || t.setConnected(!1);
  }
  render() {
    return b;
  }
}
s$3.finalized = !0, s$3._$litElement$ = !0, null === (l$2 = globalThis.litElementHydrateSupport) || void 0 === l$2 || l$2.call(globalThis, {
  LitElement: s$3
});
const n$3 = globalThis.litElementPolyfillSupport;
null == n$3 || n$3({
  LitElement: s$3
});
(null !== (o$3 = globalThis.litElementVersions) && void 0 !== o$3 ? o$3 : globalThis.litElementVersions = []).push("3.2.1");
const commonStyles = r`
  html[theme='light'] {
    background: var(--zui-color-app-bg);
  }
`;
const colors = r`
  [theme='light'] {
    /* Background Colors */
    --zui-color-app-bg: var(--zui-color-gs-10);

    --zui-color-interactive-element-bg-default-enabled: var(--zui-color-gs-30);
    --zui-color-interactive-element-bg-default-hovered: var(--zui-color-gs-40);
    --zui-color-interactive-element-bg-default-pressed: var(--zui-color-gs-50);
    --zui-color-interactive-element-bg-default-disabled: var(--zui-color-gs-30);

    --zui-color-interactive-element-bg-activated-enabled: var(--zui-color-gs-80);
    --zui-color-interactive-element-bg-activated-hovered: var(--zui-color-gs-70);
    --zui-color-interactive-element-bg-activated-pressed: var(--zui-color-gs-90);
    --zui-color-interactive-element-bg-activated-disabled: var(--zui-color-gs-80);

    --zui-color-interactive-element-bg-primary-enabled: var(--zui-color-pb-100);
    --zui-color-interactive-element-bg-primary-hovered: var(--zui-color-pb-90);
    --zui-color-interactive-element-bg-primary-pressed: var(--zui-color-pb-110);

    --zui-color-first-hierarchy-bg-default-enabled: var(--zui-color-gs-10);
    --zui-color-first-hierarchy-bg-default-hovered: var(--zui-color-gs-20);
    --zui-color-first-hierarchy-bg-default-pressed: var(--zui-color-gs-30);

    --zui-color-first-hierarchy-bg-active-enabled: var(--zui-color-gs-30);
    --zui-color-first-hierarchy-bg-active-hovered: var(--zui-color-gs-40);
    --zui-color-first-hierarchy-bg-active-pressed: var(--zui-color-gs-50);

    --zui-color-first-hierarchy-bg-active-primary-enabled: var(--zui-color-pb-100);
    --zui-color-first-hierarchy-bg-active-primary-hovered: var(--zui-color-pb-90);
    --zui-color-first-hierarchy-bg-active-primary-pressed: var(--zui-color-pb-110);

    --zui-color-second-hierarchy-bg-default-enabled: var(--zui-color-gs-20);
    --zui-color-second-hierarchy-bg-default-hovered: var(--zui-color-gs-30);
    --zui-color-second-hierarchy-bg-default-pressed: var(--zui-color-gs-40);

    --zui-color-second-hierarchy-bg-active-enabled: var(--zui-color-gs-40);
    --zui-color-second-hierarchy-bg-active-hovered: var(--zui-color-gs-50);
    --zui-color-second-hierarchy-bg-active-pressed: var(--zui-color-gs-60);

    --zui-color-second-hierarchy-bg-primary-enabled: var(--zui-color-pb-100);
    --zui-color-second-hierarchy-bg-primary-hovered: var(--zui-color-pb-110);
    --zui-color-second-hierarchy-bg-primary-pressed: var(--zui-color-pb-120);

    --zui-color-third-hierarchy-bg-default-enabled: var(--zui-color-gs-30);
    --zui-color-third-hierarchy-bg-default-hovered: var(--zui-color-gs-40);
    --zui-color-third-hierarchy-bg-default-pressed: var(--zui-color-gs-50);

    --zui-color-third-hierarchy-bg-default-primary-enabled: var(--zui-color-gs-105);
    --zui-color-third-hierarchy-bg-default-primary-hovered: var(--zui-color-gs-115);
    --zui-color-third-hierarchy-bg-default-primary-pressed: var(--zui-color-gs-120);

    --zui-color-third-hierarchy-bg-active-enabled: var(--zui-color-gs-40);
    --zui-color-third-hierarchy-bg-active-hovered: var(--zui-color-gs-50);
    --zui-color-third-hierarchy-bg-active-pressed: var(--zui-color-gs-60);

    --zui-color-fourth-hierarchy-bg-default-enabled: var(--zui-color-gs-40);
    --zui-color-fourth-hierarchy-bg-default-hovered: var(--zui-color-gs-50);
    --zui-color-fourth-hierarchy-bg-default-pressed: var(--zui-color-gs-60);

    --zui-color-text-box-bg: var(--zui-color-gs-30);

    /* Selection colors */
    --zui-color-selection-background: rgba(var(--zui-color-ac-db-110-rgb), var(--zui-selection-opacity));

    /* Dialog Box colors */
    --zui-color-dialog-box-button-bg-focused: var(--zui-color-gs-10);

    --zui-color-dialog-box-top-line: var(--zui-color-gs-90);

    --zui-color-dialog-box-separator-line: var(--zui-color-gs-40);

    /* Dialog Button colors */
    --zui-color-dialog-button-default: var(--zui-color-gs-120);

    /* ewi colors */
    --zui-color-ewi-warning: var(--zui-color-sc-y-100);
    --zui-color-ewi-info: var(--zui-color-pb-100);
    --zui-color-ewi-error: var(--zui-color-sc-r-100);
    --zui-color-ewi-question: var(--zui-color-gs-80);
    --zui-color-ewi-check: var(--zui-color-sc-g-110);

    /* Thumbnail colors */
    /* without header */
    --zui-color-thumbnail-no-header-first-hierarchy-bg-enabled: var(--zui-color-second-hierarchy-bg-default-enabled);
    --zui-color-thumbnail-no-header-first-hierarchy-bg-hovered: var(--zui-color-second-hierarchy-bg-default-hovered);
    --zui-color-thumbnail-no-header-first-hierarchy-bg-pressed: var(--zui-color-second-hierarchy-bg-default-pressed);

    --zui-color-thumbnail-no-header-second-hierarchy-bg-enabled: var(--zui-color-first-hierarchy-bg-default-enabled);
    --zui-color-thumbnail-no-header-second-hierarchy-bg-hovered: var(--zui-color-first-hierarchy-bg-default-hovered);
    --zui-color-thumbnail-no-header-second-hierarchy-bg-pressed: var(--zui-color-first-hierarchy-bg-default-pressed);

    --zui-color-thumbnail-no-header-third-hierarchy-bg-enabled: var(--zui-color-first-hierarchy-bg-default-enabled);
    --zui-color-thumbnail-no-header-third-hierarchy-bg-hovered: var(--zui-color-first-hierarchy-bg-default-hovered);
    --zui-color-thumbnail-no-header-third-hierarchy-bg-pressed: var(--zui-color-first-hierarchy-bg-default-pressed);

    /* with header */
    /* styles for header and border */
    --zui-color-thumbnail-header-first-hierarchy-bg-default-border-header-enabled: var(
      --zui-color-fourth-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-first-hierarchy-bg-default-border-header-hovered: var(
      --zui-color-fourth-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-first-hierarchy-bg-default-border-header-pressed: var(
      --zui-color-fourth-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-second-hierarchy-bg-default-border-header-enabled: var(
      --zui-color-first-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-second-hierarchy-bg-default-border-header-hovered: var(
      --zui-color-first-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-second-hierarchy-bg-default-border-header-pressed: var(
      --zui-color-first-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-third-hierarchy-bg-default-border-header-enabled: var(
      --zui-color-first-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-third-hierarchy-bg-default-border-header-hovered: var(
      --zui-color-first-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-third-hierarchy-bg-default-border-header-pressed: var(
      --zui-color-first-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-any-hierarchy-bg-active-border-header-enabled: var(
      --zui-color-interactive-element-bg-activated-enabled
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-border-header-hovered: var(
      --zui-color-interactive-element-bg-activated-hovered
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-border-header-pressed: var(
      --zui-color-interactive-element-bg-activated-pressed
    );

    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-border-header-enabled: var(
      --zui-color-interactive-element-bg-primary-enabled
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-border-header-hovered: var(
      --zui-color-interactive-element-bg-primary-hovered
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-border-header-pressed: var(
      --zui-color-interactive-element-bg-primary-pressed
    );

    /* styles for image background */
    --zui-color-thumbnail-header-first-hierarchy-bg-default-image-enabled: var(
      --zui-color-second-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-first-hierarchy-bg-default-image-hovered: var(
      --zui-color-second-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-first-hierarchy-bg-default-image-pressed: var(
      --zui-color-second-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-second-hierarchy-bg-default-image-enabled: var(
      --zui-color-third-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-second-hierarchy-bg-default-image-hovered: var(
      --zui-color-third-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-second-hierarchy-bg-default-image-pressed: var(
      --zui-color-third-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-third-hierarchy-bg-default-image-enabled: var(
      --zui-color-fourth-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-third-hierarchy-bg-default-image-hovered: var(
      --zui-color-fourth-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-third-hierarchy-bg-default-image-pressed: var(
      --zui-color-fourth-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-any-hierarchy-bg-active-image-enabled: var(
      --zui-color-third-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-image-hovered: var(
      --zui-color-third-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-image-pressed: var(
      --zui-color-third-hierarchy-bg-default-pressed
    );

    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-image-enabled: var(
      --zui-color-third-hierarchy-bg-default-enabled
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-image-hovered: var(
      --zui-color-third-hierarchy-bg-default-hovered
    );
    --zui-color-thumbnail-header-any-hierarchy-bg-active-primary-image-pressed: var(
      --zui-color-third-hierarchy-bg-default-pressed
    );

    /* styles for icon placeholder */
    --zui-color-thumbnail-placeholder-icon: var(--zui-color-gs-80);

    /* Text Colors */
    --zui-color-text-avatar: var(--zui-color-gs-10);
    --zui-color-text-default: var(--zui-color-gs-100);
    --zui-color-text-menu-bar-default-primary: var(--zui-color-gs-50);
    --zui-color-text-on-dark-bg: var(--zui-color-gs-10);
    --zui-color-text-divider: var(--zui-color-gs-85);
    --zui-color-text-primary: var(--zui-color-pb-100);
    --zui-color-text-header: var(--zui-color-gs-120);
    --zui-color-text-secondary-line: var(--zui-color-gs-85);
    --zui-color-text-disabled: var(--zui-color-gs-100);

    /* Input Placeholder Colors */
    --zui-color-placeholder-input: var(--zui-color-gs-80);

    /* Input Bottom Line Colors */
    --zui-color-input-bottom-line-default-enabled: var(--zui-color-gs-60);
    --zui-color-input-bottom-line-default-hover: var(--zui-color-gs-120);
    --zui-color-input-bottom-line-default-focused: var(--zui-color-gs-100);
    --zui-color-input-bottom-line-default-pressed: var(--zui-color-gs-120);

    /* Textarea/Comment Colors */
    --zui-color-textarea-placeholder: var(--zui-color-gs-80);
    --zui-color-textarea-bottom-line: var(--zui-color-gs-60);
    --zui-color-textarea-bottom-line-hover: var(--zui-color-gs-120);

    /* Icon Colors */
    --zui-color-icon-foreground-default: var(--zui-color-gs-10);
    --zui-color-icon-foreground-warning: var(--zui-color-gs-110);

    --zui-color-icon-background-default: currentColor;

    --zui-color-icon-interactive-bg-default-enabled: var(--zui-color-gs-100);
    --zui-color-icon-interactive-bg-default-hovered: var(--zui-color-gs-70);
    --zui-color-icon-interactive-bg-default-pressed: var(--zui-color-gs-85);

    --zui-color-icon-interactive-bg-subtle-enabled: var(--zui-color-gs-85);
    --zui-color-icon-interactive-bg-subtle-hovered: var(--zui-color-gs-60);
    --zui-color-icon-interactive-bg-subtle-pressed: var(--zui-color-gs-70);

    --zui-color-icon-interactive-bg-highlight-enabled: var(--zui-color-gs-120);
    --zui-color-icon-interactive-bg-highlight-hovered: var(--zui-color-gs-80);
    --zui-color-icon-interactive-bg-highlight-pressed: var(--zui-color-gs-85);

    /* Highlight Colors */
    --zui-color-help: var(--zui-color-gs-80);
    --zui-color-info: var(--zui-color-pb-100);
    --zui-color-success: var(--zui-color-sc-g-110);
    --zui-color-warning: var(--zui-color-sc-y-100);
    --zui-color-error: var(--zui-color-sc-r-100);

    /* Line Colors */
    --zui-color-thin-line-default: var(--zui-color-gs-40);
    --zui-color-thin-line-highlight: var(--zui-color-gs-60);
    --zui-color-thick-line-default: var(--zui-color-gs-40);
    --zui-color-thick-line-highlight: var(--zui-color-gs-60);
    --zui-color-thick-line-primary: var(--zui-color-pb-100);

    --zui-color-interactive-element-thin-line-default-enabled: var(--zui-color-gs-60);
    --zui-color-interactive-element-thin-line-default-hovered: var(--zui-color-gs-60);
    --zui-color-interactive-element-thin-line-default-pressed: var(--zui-color-gs-60);
    --zui-color-interactive-element-thin-line-default-disabled: var(--zui-color-gs-60);

    --zui-color-interactive-element-thin-line-activated-enabled: var(--zui-color-gs-90);
    --zui-color-interactive-element-thin-line-activated-hovered: var(--zui-color-gs-90);
    --zui-color-interactive-element-thin-line-activated-pressed: var(--zui-color-gs-90);
    --zui-color-interactive-element-thin-line-activated-disabled: var(--zui-color-gs-90);

    --zui-color-interactive-element-thin-line-primary-enabled: var(--zui-color-gs-120);
    --zui-color-interactive-element-thin-line-primary-hovered: var(--zui-color-gs-120);
    --zui-color-interactive-element-thin-line-primary-pressed: var(--zui-color-gs-120);

    --zui-color-item-separator-line-first-hierarchy-default-enabled: var(--zui-color-gs-40);
    --zui-color-item-separator-line-first-hierarchy-default-hovered: var(--zui-color-gs-40);
    --zui-color-item-separator-line-first-hierarchy-default-focused: var(--zui-color-gs-100);
    --zui-color-item-separator-line-first-hierarchy-default-pressed: var(--zui-color-gs-40);

    --zui-color-item-separator-line-first-hierarchy-active-enabled: var(--zui-color-gs-40);
    --zui-color-item-separator-line-first-hierarchy-active-hovered: var(--zui-color-gs-40);
    --zui-color-item-separator-line-first-hierarchy-active-focused: var(--zui-color-gs-100);
    --zui-color-item-separator-line-first-hierarchy-active-pressed: var(--zui-color-gs-40);

    --zui-color-item-separator-line-first-hierarchy-primary-enabled: var(--zui-color-pb-120);
    --zui-color-item-separator-line-first-hierarchy-primary-hovered: var(--zui-color-pb-120);
    --zui-color-item-separator-line-first-hierarchy-primary-focused: var(--zui-color-gs-120);
    --zui-color-item-separator-line-first-hierarchy-primary-pressed: var(--zui-color-pb-120);

    --zui-color-item-separator-line-second-hierarchy-default-enabled: var(--zui-color-gs-60);
    --zui-color-item-separator-line-second-hierarchy-default-hovered: var(--zui-color-gs-60);
    --zui-color-item-separator-line-second-hierarchy-default-focused: var(--zui-color-gs-100);
    --zui-color-item-separator-line-second-hierarchy-default-pressed: var(--zui-color-gs-60);

    --zui-color-item-separator-line-second-hierarchy-active-enabled: var(--zui-color-gs-60);
    --zui-color-item-separator-line-second-hierarchy-active-hovered: var(--zui-color-gs-60);
    --zui-color-item-separator-line-second-hierarchy-active-focused: var(--zui-color-gs-100);
    --zui-color-item-separator-line-second-hierarchy-active-pressed: var(--zui-color-gs-60);

    --zui-color-item-separator-line-second-hierarchy-primary-enabled: var(--zui-color-pb-120);
    --zui-color-item-separator-line-second-hierarchy-primary-hovered: var(--zui-color-pb-120);
    --zui-color-item-separator-line-second-hierarchy-primary-focused: var(--zui-color-gs-120);
    --zui-color-item-separator-line-second-hierarchy-primary-pressed: var(--zui-color-pb-120);

    --zui-color-tooltip-border: var(--zui-color-gs-60);

    /* State Display */
    --zui-color-interactive-element-state-icon-outline-enabled: var(--zui-color-gs-10);
    --zui-color-interactive-element-state-icon-outline-hovered: var(--zui-color-gs-10);
    --zui-color-interactive-element-state-icon-outline-pressed: var(--zui-color-gs-10);
    --zui-color-interactive-element-state-icon-outline-disabled: var(--zui-color-gs-10);

    --zui-color-interactive-element-state-icon-filled-enabled: var(--zui-color-gs-10);
    --zui-color-interactive-element-state-icon-filled-hovered: var(--zui-color-gs-30);
    --zui-color-interactive-element-state-icon-filled-pressed: var(--zui-color-gs-10);
    --zui-color-interactive-element-state-icon-filled-disabled: var(--zui-color-gs-10);

    /* Shadow Colors*/
    --zui-color-box-shadow-default: rgba(0, 0, 0, 0.2);

    /* drag-and-drop-area */
    --zui-color-drag-drop-background-first-hierarchy-default-enabled: var(--zui-color-gs-30);
    --zui-color-drag-drop-background-first-hierarchy-default-disabled: var(--zui-color-gs-30);
    --zui-color-drag-drop-background-first-hierarchy-default-hover: var(--zui-color-gs-35);
    --zui-color-drag-drop-background-first-hierarchy-default-pressed: var(--zui-color-gs-40);

    --zui-color-drag-drop-background-second-hierarchy-default-enabled: var(--zui-color-gs-10);
    --zui-color-drag-drop-background-second-hierarchy-default-disabled: var(--zui-color-gs-10);
    --zui-color-drag-drop-background-second-hierarchy-default-hover: var(--zui-color-gs-20);
    --zui-color-drag-drop-background-second-hierarchy-default-pressed: var(--zui-color-gs-30);

    --zui-color-drag-drop-background-first-hierarchy-active: var(--zui-color-gs-30);
    --zui-color-drag-drop-background-second-hierarchy-active: var(--zui-color-gs-10);

    --zui-color-drag-drop-border-default: var(--zui-color-gs-70);
    --zui-color-drag-drop-border-active: var(--zui-color-pb-100);
    --zui-color-drag-drop-font: var(--zui-color-gs-85);
    --zui-color-drag-drop-icon: var(--zui-color-gs-85);

    /* inline-message */
    --zui-color-inline-message-message-text-with-header: var(--zui-color-gs-80);
    --zui-color-inline-message-message-text-without-header: var(--zui-color-gs-100);
    --zui-color-inline-message-header-text: var(--zui-color-gs-100);

    /* radio button */
    --zui-color-radio-button-knob-enabled: var(--zui-color-gs-10);
    --zui-color-radio-button-knob-hovered: var(--zui-color-gs-30);

    --zui-color-radio-button-border-default: var(--zui-color-gs-60);
    --zui-color-radio-button-border-active: var(--zui-color-gs-90);

    /* progress ring component */
    --zui-color-progress-ring-default-background: var(--zui-color-gs-50);
    --zui-color-progress-ring-default-foreground: var(--zui-color-gs-85);
    --zui-color-progress-ring-highlight-background: var(--zui-color-gs-50);
    --zui-color-progress-ring-highlight-foreground: var(--zui-color-pb-100);
    --zui-color-progress-ring-primary-highlight-background: var(--zui-color-gs-10);
    --zui-color-progress-ring-primary-highlight-foreground: var(--zui-color-gs-10);

    --zui-color-progress-ring-default-text: var(--zui-color-gs-85);
    --zui-color-progress-ring-highlight-text: var(--zui-color-gs-85);
    --zui-color-progress-ring-primary-highlight-text: var(--zui-color-gs-10);

    /* progress bar component */
    --zui-color-progress-bar-highlight-background: var(--zui-color-gs-50);
    --zui-color-progress-bar-highlight-foreground: var(--zui-color-gs-85);

    --zui-color-progress-bar-primary-highlight-background: var(--zui-color-gs-50);
    --zui-color-progress-bar-primary-highlight-foreground: var(--zui-color-pb-100);

    --zui-color-progress-bar-striped-background: var(--zui-color-pb-50);
    --zui-color-progress-bar-striped-foreground-left-tile: var(--zui-color-pb-100);
    --zui-color-progress-bar-striped-background-right-tile: var(--zui-color-pb-90);

    /* toast notification component */
    --zui-color-toast-notification-border-color: var(--zui-color-gs-60);
    --zui-color-toast-notification-border-color-inner: var(--zui-color-gs-40);

    /* ewiq dialog component */
    --zui-color-ewiq-dialog-header-text-color: var(--zui-color-gs-120);
    --zui-color-ewiq-dialog-description-text-color: var(--zui-color-gs-85);
    --zui-color-ewiq-dialog-description-safety-text-color: var(--zui-color-gs-100);
    --zui-color-ewiq-dialog-question-text-color: var(--zui-color-gs-100);
    --zui-color-ewiq-dialog-content-text-color: var(--zui-color-gs-100);
    --zui-color-ewiq-dialog-content-background: var(--zui-color-gs-20);

    /* list item component*/
    --zui-color-list-item-first-hierarchy-default-enabled: var(--zui-color-gs-10);
    --zui-color-list-item-first-hierarchy-default-hovered: var(--zui-color-gs-20);
    --zui-color-list-item-first-hierarchy-default-focused: var(--zui-color-gs-10);
    --zui-color-list-item-first-hierarchy-default-pressed: var(--zui-color-gs-30);

    --zui-color-list-item-first-hierarchy-active-enabled: var(--zui-color-gs-30);
    --zui-color-list-item-first-hierarchy-active-hovered: var(--zui-color-gs-40);
    --zui-color-list-item-first-hierarchy-active-focused: var(--zui-color-gs-30);
    --zui-color-list-item-first-hierarchy-active-pressed: var(--zui-color-gs-50);

    --zui-color-list-item-first-hierarchy-active-primary-enabled: var(--zui-color-pb-100);
    --zui-color-list-item-first-hierarchy-active-primary-hovered: var(--zui-color-pb-90);
    --zui-color-list-item-first-hierarchy-active-primary-focused: var(--zui-color-pb-100);
    --zui-color-list-item-first-hierarchy-active-primary-pressed: var(--zui-color-pb-110);

    --zui-color-list-item-second-hierarchy-default-enabled: var(--zui-color-gs-20);
    --zui-color-list-item-second-hierarchy-default-hovered: var(--zui-color-gs-30);
    --zui-color-list-item-second-hierarchy-default-focused: var(--zui-color-gs-20);
    --zui-color-list-item-second-hierarchy-default-pressed: var(--zui-color-gs-40);

    --zui-color-list-item-second-hierarchy-active-enabled: var(--zui-color-gs-40);
    --zui-color-list-item-second-hierarchy-active-hovered: var(--zui-color-gs-50);
    --zui-color-list-item-second-hierarchy-active-focused: var(--zui-color-gs-40);
    --zui-color-list-item-second-hierarchy-active-pressed: var(--zui-color-gs-60);

    --zui-color-list-item-second-hierarchy-active-primary-enabled: var(--zui-color-pb-100);
    --zui-color-list-item-second-hierarchy-active-primary-hovered: var(--zui-color-pb-110);
    --zui-color-list-item-second-hierarchy-active-primary-focused: var(--zui-color-pb-100);
    --zui-color-list-item-second-hierarchy-active-primary-pressed: var(--zui-color-pb-120);

    /* scrollbar component */
    --zui-color-scrollbar-background: var(--zui-color-gs-30);
    --zui-color-scrollbar-track: var(--zui-color-gs-60);
    --zui-color-scrollbar-handle: var(--zui-color-gs-60);
    --zui-color-scrollbar-handle-hover: var(--zui-color-gs-85);

    /* searchbar component */
    --zui-color-searchbar-search-icon: var(--zui-color-gs-85);
    --zui-color-searchbar-placeholder: var(--zui-color-gs-80);
    --zui-color-searchbar-input-background: var(--zui-color-gs-30);

    /* select component */
    --zui-color-select-button-watermark: var(--zui-color-gs-80);
    --zui-color-select-button-selected: var(--zui-color-gs-100);

    --zui-color-select-arrow-enabled: var(--zui-color-gs-85);
    --zui-color-select-arrow-hovered: var(--zui-color-gs-100);
    --zui-color-select-arrow-focused: var(--zui-color-gs-85);
    --zui-color-select-arrow-pressed: var(--zui-color-gs-100);

    --zui-color-select-bottom-line-watermark-enabled: var(--zui-color-gs-60);
    --zui-color-select-bottom-line-watermark-hovered: var(--zui-color-gs-120);
    --zui-color-select-bottom-line-watermark-focused: var(--zui-color-gs-60);
    --zui-color-select-bottom-line-watermark-pressed: var(--zui-color-gs-100);
    --zui-color-select-bottom-line-selected-enabled: var(--zui-color-gs-60);
    --zui-color-select-bottom-line-selected-hovered: var(--zui-color-gs-120);
    --zui-color-select-bottom-line-selected-focused: var(--zui-color-gs-60);
    --zui-color-select-bottom-line-selected-pressed: var(--zui-color-gs-100);
    --zui-color-select-bottom-line-opened-enabled: var(--zui-color-gs-120);
    --zui-color-select-bottom-line-opened-hovered: var(--zui-color-gs-120);
    --zui-color-select-bottom-line-opened-focused: var(--zui-color-gs-120);
    --zui-color-select-bottom-line-opened-pressed: var(--zui-color-gs-120);

    /* menu component */
    --zui-color-menu-border: var(--zui-color-gs-60);

    --zui-color-menu-item-background-default-enabled: var(--zui-color-second-hierarchy-bg-default-enabled);
    --zui-color-menu-item-background-default-focused: var(--zui-color-second-hierarchy-bg-default-hovered);
    --zui-color-menu-item-background-default-hovered: var(--zui-color-second-hierarchy-bg-default-hovered);
    --zui-color-menu-item-background-default-pressed: var(--zui-color-second-hierarchy-bg-default-pressed);

    --zui-color-menu-item-background-selected-enabled: var(--zui-color-second-hierarchy-bg-active-enabled);
    --zui-color-menu-item-background-selected-focused: var(--zui-color-second-hierarchy-bg-active-hovered);
    --zui-color-menu-item-background-selected-hovered: var(--zui-color-second-hierarchy-bg-active-hovered);
    --zui-color-menu-item-background-selected-pressed: var(--zui-color-second-hierarchy-bg-active-pressed);

    --zui-color-menu-item-background-primary-enabled: var(--zui-color-second-hierarchy-bg-primary-enabled);
    --zui-color-menu-item-background-primary-focused: var(--zui-color-second-hierarchy-bg-primary-hovered);
    --zui-color-menu-item-background-primary-hovered: var(--zui-color-second-hierarchy-bg-primary-hovered);
    --zui-color-menu-item-background-primary-pressed: var(--zui-color-second-hierarchy-bg-primary-pressed);

    /* state dot component */
    --zui-color-state-dot-default: var(--zui-color-gs-50);

    /* state label */
    --zui-color-state-label-background-color-default: var(--zui-color-gs-35);
    --zui-color-state-label-background-color-error: rgba(var(--zui-color-sc-r-100-rgb), 0.2);
    --zui-color-state-label-background-color-progress: rgba(var(--zui-color-pb-100-rgb), 0.2);
    --zui-color-state-label-background-color-ready: rgba(var(--zui-color-sc-g-110-rgb), 0.2);
    --zui-color-state-label-background-color-warning: rgba(var(--zui-color-sc-y-100-rgb), 0.2);
    --zui-color-state-label-text-color-default: var(--zui-color-gs-100);
    --zui-color-state-label-text-color-error: var(--zui-color-sc-r-100);
    --zui-color-state-label-text-color-progress: var(--zui-color-pb-100);
    --zui-color-state-label-text-color-ready: var(--zui-color-sc-g-110);
    --zui-color-state-label-text-color-warning: var(--zui-color-sc-y-110);

    /* pill component */
    --zui-color-pill-background: var(--zui-color-gs-30);
    --zui-color-pill-border: var(--zui-color-gs-60);
    --zui-color-pill-text: var(--zui-color-gs-100);

    /* Slider */
    --zui-color-slider-track-enabled: var(--zui-color-gs-60);
    --zui-color-slider-track-disabled: var(--zui-color-gs-60);
    --zui-color-slider-track-hover: var(--zui-color-gs-60);

    --zui-color-slider-handle-fill-enabled: var(--zui-color-gs-40);
    --zui-color-slider-handle-fill-disabled: var(--zui-color-gs-30);
    --zui-color-slider-handle-fill-disabled-readonly: var(--zui-color-gs-60);
    --zui-color-slider-handle-fill-hover: var(--zui-color-gs-30);

    --zui-color-slider-handle-fill-ghost: var(--zui-color-gs-30);

    --zui-color-slider-handle-border-enabled: var(--zui-color-gs-90);
    --zui-color-slider-handle-border-disabled: var(--zui-color-gs-60);
    --zui-color-slider-handle-border-hover: var(--zui-color-gs-100);

    --zui-color-slider-handle-border-ghost: var(--zui-color-gs-60);

    --zui-color-slider-active-line-enabled: var(--zui-color-gs-90);
    --zui-color-slider-active-line-disabled: var(--zui-color-gs-60);
    --zui-color-slider-active-line-hover: var(--zui-color-gs-90);

    --zui-color-slider-step: var(--zui-color-gs-60);
    --zui-color-slider-tick-label: var(--zui-color-gs-85);

    /** slider readonly **/
    --zui-color-slider-track-readonly-enabled: var(--zui-color-gs-60);
    --zui-color-slider-track-readonly-disabled: var(--zui-color-gs-60);

    --zui-color-slider-handle-fill-readonly-enabled: var(--zui-color-gs-90);
    --zui-color-slider-handle-fill-readonly-disabled: var(--zui-color-gs-60);

    --zui-color-slider-active-line-readonly-enabled: var(--zui-color-gs-90);
    --zui-color-slider-active-line-readonly-disabled: var(--zui-color-gs-60);

    /* date picker input */
    // prettier-ignore
    --zui-date-picker-input-border-bottom-disabled-color: rgba(
      var(--zui-color-gs-60-rgb),
      var(--zui-disabled-opacity)
    );

    /* date picker component */
    /* grid cell component */
    --zui-color-grid-cell-background-selected: var(--zui-color-gs-80);

    --zui-color-grid-cell-text-default-enabled: var(--zui-color-gs-120);
    --zui-color-grid-cell-text-default-disabled: var(--zui-color-gs-80);
    --zui-color-grid-cell-text-default-hover: var(--zui-color-gs-100);

    --zui-color-grid-cell-text-selected: var(--zui-color-gs-10);

    --zui-color-grid-cell-text-subtle: var(--zui-color-gs-80);

    --zui-color-grid-cell-border-hover: var(--zui-color-gs-40);
    --zui-color-grid-cell-border-pressed: var(--zui-color-gs-60);

    /* user menu button component */
    /* default */
    --zui-color-user-menu-button-user-name-default: var(--zui-color-gs-100);
    --zui-color-user-menu-button-user-role-default: var(--zui-color-gs-85);
    --zui-color-user-menu-button-background-color-default: transparent;

    --zui-color-user-menu-button-user-name-hover-default: var(--zui-color-gs-70);
    --zui-color-user-menu-button-user-role-hover-default: var(--zui-color-gs-60);

    --zui-color-user-menu-button-user-name-focus-default: var(--zui-color-gs-100);
    --zui-color-user-menu-button-user-role-focus-default: var(--zui-color-gs-85);

    --zui-color-user-menu-button-user-name-active-default: var(--zui-color-gs-85);
    --zui-color-user-menu-button-user-role-active-default: var(--zui-color-gs-70);

    /* selected */
    --zui-color-user-menu-button-user-name-selected: var(--zui-color-gs-120);
    --zui-color-user-menu-button-user-role-selected: var(--zui-color-gs-100);
    --zui-color-user-menu-button-background-color-selected: var(--zui-color-gs-30);

    --zui-color-user-menu-button-user-name-hover-selected: var(--zui-color-gs-80);
    --zui-color-user-menu-button-user-role-hover-selected: var(--zui-color-gs-70);

    --zui-color-user-menu-button-user-name-focus-selected: var(--zui-color-gs-120);
    --zui-color-user-menu-button-user-role-focus-selected: var(--zui-color-gs-100);

    --zui-color-user-menu-button-user-name-active-selected: var(--zui-color-gs-85);
    --zui-color-user-menu-button-user-role-active-selected: var(--zui-color-gs-85);

    /* headerbar component */
    --zui-color-headerbar-background: var(--zui-color-gs-10);
    --zui-color-headerbar-button-divider-border-color: var(--zui-color-gs-40);

    /* headerbar icon button component */
    --zui-color-headerbar-icon-button-background-color-selected: var(--zui-color-gs-30);

    /* headerbar product name component */
    --zui-color-headerbar-product-name-color: var(--zui-color-gs-100);
    --zui-color-headerbar-product-sub-name-color: var(--zui-color-gs-85);

    /* searchbar component */
    --zui-color-searchbar-filter-divider: var(--zui-color-gs-60);
    --zui-color-searchbar-query-text: var(--zui-color-gs-100);

    /* picker header text sub component */
    --zui-color-picker-header-text-color-default: var(--zui-color-text-default);
    --zui-color-picker-header-text-color-disabled: var(--zui-color-gs-60);
    --zui-color-picker-header-text-color-hovered: var(--zui-color-gs-80);
    --zui-color-picker-header-text-color-pressed: var(--zui-color-gs-80);
    --zui-color-picker-header-text-color-active: var(--zui-color-gs-80);

    /* list accordion */
    --zui-color-list-accordion-s-closed-header-text-icon: var(--zui-color-gs-85);
    --zui-color-list-accordion-closed-header-text-icon-default: var(--zui-color-gs-100);
    --zui-color-list-accordion-closed-header-text-icon-selected: var(--zui-color-gs-120);
    --zui-color-list-accordion-closed-header-text-icon-selected-primary: var(--zui-color-gs-10);
    --zui-color-list-accordion-open-header-text-icon-default: var(--zui-color-gs-120);
    --zui-color-list-accordion-open-header-text-icon-selected: var(--zui-color-gs-120);
    --zui-color-list-accordion-open-header-text-icon-selected-primary: var(--zui-color-gs-10);

    --zui-color-list-accordion-header-background-default-default: var(--zui-color-gs-10);
    --zui-color-list-accordion-header-background-default-hover: var(--zui-color-gs-20);
    --zui-color-list-accordion-header-background-default-focus: var(--zui-color-gs-10);
    --zui-color-list-accordion-header-background-default-active: var(--zui-color-gs-30);

    --zui-color-list-accordion-header-background-selected-default: var(--zui-color-gs-30);
    --zui-color-list-accordion-header-background-selected-hover: var(--zui-color-gs-40);
    --zui-color-list-accordion-header-background-selected-focus: var(--zui-color-gs-30);
    --zui-color-list-accordion-header-background-selected-active: var(--zui-color-gs-50);

    --zui-color-list-accordion-header-background-selected-primary-default: var(--zui-color-pb-100);
    --zui-color-list-accordion-header-background-selected-primary-hover: var(--zui-color-pb-90);
    --zui-color-list-accordion-header-background-selected-primary-focus: var(--zui-color-pb-100);
    --zui-color-list-accordion-header-background-selected-primary-active: var(--zui-color-pb-110);

    --zui-color-list-accordion-header-top-line-default: var(--zui-color-gs-40);
    --zui-color-list-accordion-header-bottom-line-default: var(--zui-color-gs-40);
    --zui-color-list-accordion-header-bottom-line-hover: var(--zui-color-gs-40);
    --zui-color-list-accordion-header-bottom-line-focus: var(--zui-color-gs-100);
    --zui-color-list-accordion-header-bottom-line-active: var(--zui-color-gs-40);

    /* list accordion item */
    --zui-color-list-accordion-item-primary-text-default: var(--zui-color-gs-100);
    --zui-color-list-accordion-item-primary-text-selected: var(--zui-color-gs-120);
    --zui-color-list-accordion-item-primary-text-selected-primary: var(--zui-color-gs-10);

    --zui-color-list-accordion-item-background-default: var(--zui-color-list-item-second-hierarchy-default-enabled);
    --zui-color-list-accordion-item-background-selected: var(--zui-color-list-item-second-hierarchy-active-enabled);
    --zui-color-list-accordion-item-background-selected-primary: var(
      --zui-color-list-item-second-hierarchy-active-primary-enabled
    );

    --zui-color-list-accordion-item-border-bottom-default: var(--zui-color-gs-60);
    --zui-color-list-accordion-item-border-bottom-last-default: var(--zui-color-gs-40);
    --zui-color-list-accordion-item-border-bottom-default-hover: var(--zui-color-gs-60);
    --zui-color-list-accordion-item-border-bottom-default-focus: var(--zui-color-gs-100);
    --zui-color-list-accordion-item-border-bottom-default-active: var(--zui-color-gs-60);

    --zui-color-list-accordion-item-border-bottom-selected: var(--zui-color-gs-60);
    --zui-color-list-accordion-item-border-bottom-selected-hover: var(--zui-color-gs-60);
    --zui-color-list-accordion-item-border-bottom-selected-focus: var(--zui-color-gs-100);
    --zui-color-list-accordion-item-border-bottom-selected-active: var(--zui-color-gs-60);

    --zui-color-list-accordion-item-border-bottom-selected-primary: var(--zui-color-pb-120);
    --zui-color-list-accordion-item-border-bottom-selected-primary-hover: var(--zui-color-pb-120);
    --zui-color-list-accordion-item-border-bottom-selected-primary-focus: var(--zui-color-gs-120);
    --zui-color-list-accordion-item-border-bottom-selected-primary-active: var(--zui-color-pb-120);

    --zui-color-list-accordion-item-background-default-hover: var(
      --zui-color-list-item-second-hierarchy-default-hovered
    );
    --zui-color-list-accordion-item-background-selected-hover: var(
      --zui-color-list-item-second-hierarchy-active-hovered
    );
    --zui-color-list-accordion-item-background-selected-primary-hover: var(
      --zui-color-list-item-second-hierarchy-active-primary-hovered
    );

    --zui-color-list-accordion-item-background-default-focus: var(
      --zui-color-list-item-second-hierarchy-default-focused
    );
    --zui-color-list-accordion-item-background-selected-focus: var(
      --zui-color-list-item-second-hierarchy-active-focused
    );
    --zui-color-list-accordion-item-background-selected-primary-focus: var(
      --zui-color-list-item-second-hierarchy-active-primary-focused
    );

    --zui-color-list-accordion-item-background-default-active: var(
      --zui-color-list-item-second-hierarchy-default-pressed
    );
    --zui-color-list-accordion-item-background-selected-active: var(
      --zui-color-list-item-second-hierarchy-active-pressed
    );
    --zui-color-list-accordion-item-background-selected-primary-active: var(
      --zui-color-list-item-second-hierarchy-active-primary-pressed
    );

    /* pagination dot */
    --zui-color-pagination-dot-background-default: var(--zui-color-gs-50);
    --zui-color-pagination-dot-background-selected: var(--zui-color-pb-100);

    /* menu tab item */
    --zui-color-menu-tab-item-default-background-active: var(--zui-color-gs-30);
    --zui-color-menu-tab-item-default-background-focus: var(--zui-color-gs-10);
    --zui-color-menu-tab-item-default-background-hover: var(--zui-color-gs-20);
    --zui-color-menu-tab-item-default-background: var(--zui-color-gs-10);
    --zui-color-menu-tab-item-default: var(--zui-color-gs-100);

    --zui-color-menu-tab-item-selected-background: var(--zui-color-gs-100);
    --zui-color-menu-tab-item-selected-border-bottom: var(--zui-color-pb-100);
    --zui-color-menu-tab-item-selected: var(--zui-color-gs-120);

    /* title stack */
    --zui-color-title-stack-label: var(--zui-color-gs-85);
    --zui-color-title-stack-headline: var(--zui-color-gs-100);
    --zui-color-title-stack-separator: var(--zui-color-gs-60);
    --zui-color-title-stack-meta: var(--zui-color-gs-85);

    /* toggle switch */
    --zui-color-toggle-switch-default-background-color: var(--zui-color-gs-50);
    --zui-color-toggle-switch-default-background-color-disabled: var(--zui-color-gs-50);
    --zui-color-toggle-switch-default-background-color-hover: var(--zui-color-gs-40);
    --zui-color-toggle-switch-default-background-color-focus: var(--zui-color-gs-50);
    --zui-color-toggle-switch-default-background-color-active: var(--zui-color-gs-60);

    --zui-color-toggle-switch-default-border-color: var(--zui-color-gs-70);
    --zui-color-toggle-switch-default-border-color-disabled: var(--zui-color-gs-70);

    --zui-color-toggle-switch-default-checked-background-color: var(--zui-color-gs-80);
    --zui-color-toggle-switch-default-checked-background-color-disabled: var(--zui-color-gs-80);
    --zui-color-toggle-switch-default-checked-background-color-hover: var(--zui-color-gs-70);
    --zui-color-toggle-switch-default-checked-background-color-focus: var(--zui-color-gs-80);
    --zui-color-toggle-switch-default-checked-background-color-active: var(--zui-color-gs-90);

    --zui-color-toggle-switch-default-checked-border-color: var(--zui-color-gs-90);
    --zui-color-toggle-switch-default-checked-border-color-disabled: var(--zui-color-gs-90);

    --zui-color-toggle-switch-primary-checked-background-color: var(--zui-color-pb-100);
    --zui-color-toggle-switch-primary-checked-background-color-disabled: var(--zui-color-pb-100);
    --zui-color-toggle-switch-primary-checked-background-color-hover: var(--zui-color-pb-90);
    --zui-color-toggle-switch-primary-checked-background-color-focus: var(--zui-color-pb-100);
    --zui-color-toggle-switch-primary-checked-background-color-active: var(--zui-color-pb-110);

    --zui-color-toggle-switch-primary-checked-border-color: var(--zui-color-pb-120);
    --zui-color-toggle-switch-primary-checked-border-color-disabled: var(--zui-color-pb-120);

    /* about screen */
    --zui-color-about-screen-header-background-color-default: var(--zui-color-gs-20);
    --zui-color-about-screen-content-background-color-default: var(--zui-color-gs-10);
    --zui-color-about-screen-footer-background-color-default: var(--zui-color-gs-10);

    --zui-color-about-screen-header-background-color-inverted: var(--zui-color-gs-10);
    --zui-color-about-screen-content-background-color-inverted: var(--zui-color-gs-20);
    --zui-color-about-screen-footer-background-color-inverted: var(--zui-color-gs-20);

    --zui-color-about-screen-software-icon-color: var(--zui-color-gs-100);
    --zui-color-about-screen-software-name-color: var(--zui-color-gs-100);
    --zui-color-about-screen-software-version-color: var(--zui-color-gs-85);
    --zui-color-about-screen-copyright-claim-color: var(--zui-color-gs-85);
    --zui-color-about-screen-licence-title-color: var(--zui-color-gs-120);
    --zui-color-about-screen-small-licence-title-color: var(--zui-color-gs-85);

    /* content tab default item */
    /* text color for emphasis: default */
    --zui-content-tab-default-item-default-text-enabled: var(--zui-color-gs-85);
    --zui-content-tab-default-item-default-text-disabled: var(--zui-color-gs-85);
    --zui-content-tab-default-item-default-text-hovered: var(--zui-color-gs-100);
    --zui-content-tab-default-item-default-text-focused: var(--zui-color-gs-85);
    --zui-content-tab-default-item-default-text-pressed: var(--zui-color-gs-100);

    /* text color for emphasis: selected */
    --zui-content-tab-default-item-selected-text-enabled: var(--zui-color-gs-120);
    --zui-content-tab-default-item-selected-text-disabled: var(--zui-color-gs-120);
    --zui-content-tab-default-item-selected-text-hovered: var(--zui-color-gs-120);
    --zui-content-tab-default-item-selected-text-focused: var(--zui-color-gs-120);
    --zui-content-tab-default-item-selected-text-pressed: var(--zui-color-gs-120);

    /* background for emphasis: default */
    --zui-content-tab-default-item-default-background-enabled: var(--zui-color-gs-30);
    --zui-content-tab-default-item-default-background-disabled: var(--zui-color-gs-30);
    --zui-content-tab-default-item-default-background-hovered: var(--zui-color-gs-40);
    --zui-content-tab-default-item-default-background-focused: var(--zui-color-gs-30);
    --zui-content-tab-default-item-default-background-pressed: var(--zui-color-gs-50);

    /* background for emphasis: selected and hierarchy level: 1 */
    --zui-content-tab-default-item-selected-1-background-enabled: var(--zui-color-gs-10);
    --zui-content-tab-default-item-selected-1-background-disabled: var(--zui-color-gs-10);
    --zui-content-tab-default-item-selected-1-background-hovered: var(--zui-color-gs-10);
    --zui-content-tab-default-item-selected-1-background-focused: var(--zui-color-gs-10);
    --zui-content-tab-default-item-selected-1-background-pressed: var(--zui-color-gs-10);

    /* background for emphasis: selected and hierarchy level: 2 */
    --zui-content-tab-default-item-selected-2-background-enabled: var(--zui-color-gs-20);
    --zui-content-tab-default-item-selected-2-background-disabled: var(--zui-color-gs-20);
    --zui-content-tab-default-item-selected-2-background-hovered: var(--zui-color-gs-20);
    --zui-content-tab-default-item-selected-2-background-focused: var(--zui-color-gs-20);
    --zui-content-tab-default-item-selected-2-background-pressed: var(--zui-color-gs-20);

    /* content tab highlight item */
    /* text color for emphasis: default */
    --zui-content-tab-highlight-item-default-text-enabled: var(--zui-color-gs-100);
    --zui-content-tab-highlight-item-default-text-disabled: var(--zui-color-gs-100);
    --zui-content-tab-highlight-item-default-text-hovered: var(--zui-color-gs-100);
    --zui-content-tab-highlight-item-default-text-focused: var(--zui-color-gs-100);
    --zui-content-tab-highlight-item-default-text-pressed: var(--zui-color-gs-100);

    /* text color for emphasis: selected */
    --zui-content-tab-highlight-item-selected-text-enabled: var(--zui-color-gs-120);
    --zui-content-tab-highlight-item-selected-text-disabled: var(--zui-color-gs-120);
    --zui-content-tab-highlight-item-selected-text-hovered: var(--zui-color-gs-120);
    --zui-content-tab-highlight-item-selected-text-focused: var(--zui-color-gs-120);
    --zui-content-tab-highlight-item-selected-text-pressed: var(--zui-color-gs-120);

    /* background for emphasis: default */
    --zui-content-tab-highlight-item-default-background-enabled: var(--zui-color-gs-30);
    --zui-content-tab-highlight-item-default-background-disabled: var(--zui-color-gs-30);
    --zui-content-tab-highlight-item-default-background-hovered: var(--zui-color-gs-40);
    --zui-content-tab-highlight-item-default-background-focused: var(--zui-color-gs-30);
    --zui-content-tab-highlight-item-default-background-pressed: var(--zui-color-gs-50);

    /* background for emphasis: selected and hierarchy level: 1 */
    --zui-content-tab-highlight-item-selected-1-background-enabled: var(--zui-color-gs-10);
    --zui-content-tab-highlight-item-selected-1-background-disabled: var(--zui-color-gs-10);
    --zui-content-tab-highlight-item-selected-1-background-hovered: var(--zui-color-gs-10);
    --zui-content-tab-highlight-item-selected-1-background-focused: var(--zui-color-gs-10);
    --zui-content-tab-highlight-item-selected-1-background-pressed: var(--zui-color-gs-10);

    /* background for emphasis: selected and hierarchy level: 2 */
    --zui-content-tab-highlight-item-selected-2-background-enabled: var(--zui-color-gs-20);
    --zui-content-tab-highlight-item-selected-2-background-disabled: var(--zui-color-gs-20);
    --zui-content-tab-highlight-item-selected-2-background-hovered: var(--zui-color-gs-20);
    --zui-content-tab-highlight-item-selected-2-background-focused: var(--zui-color-gs-20);
    --zui-content-tab-highlight-item-selected-2-background-pressed: var(--zui-color-gs-20);

    /* border-top */
    --zui-content-tab-highlight-item-selected-border: var(--zui-color-gs-95);

    /* content tab image item */
    /* text color for emphasis: default */
    --zui-content-tab-image-item-default-text-enabled: var(--zui-color-gs-80);
    --zui-content-tab-image-item-default-text-disabled: var(--zui-color-gs-80);
    --zui-content-tab-image-item-default-text-hovered: var(--zui-color-gs-50);
    --zui-content-tab-image-item-default-text-focused: var(--zui-color-gs-80);
    --zui-content-tab-image-item-default-text-pressed: var(--zui-color-gs-50);

    /* background for emphasis: default */
    --zui-content-tab-image-item-default-background-enabled: var(--zui-color-gs-115);
    --zui-content-tab-image-item-default-background-disabled: var(--zui-color-gs-115);
    --zui-content-tab-image-item-default-background-hovered: var(--zui-color-gs-115);
    --zui-content-tab-image-item-default-background-focused: var(--zui-color-gs-115);
    --zui-content-tab-image-item-default-background-pressed: var(--zui-color-gs-115);

    /* text color for emphasis: selected */
    --zui-content-tab-image-item-selected-text-enabled: var(--zui-color-gs-10);
    --zui-content-tab-image-item-selected-text-disabled: var(--zui-color-gs-10);
    --zui-content-tab-image-item-selected-text-hovered: var(--zui-color-gs-10);
    --zui-content-tab-image-item-selected-text-focused: var(--zui-color-gs-10);
    --zui-content-tab-image-item-selected-text-pressed: var(--zui-color-gs-10);

    /* background for emphasis: selected */
    --zui-content-tab-image-item-selected-background-enabled: var(--zui-color-gs-105);
    --zui-content-tab-image-item-selected-background-disabled: var(--zui-color-gs-105);
    --zui-content-tab-image-item-selected-background-hovered: var(--zui-color-gs-105);
    --zui-content-tab-image-item-selected-background-focused: var(--zui-color-gs-105);
    --zui-content-tab-image-item-selected-background-pressed: var(--zui-color-gs-105);

    /* content tab bar */
    --zui-content-tab-bar-rectangle-background: var(--zui-color-gs-30);

    /* color override for the close icon (always dark mode) */
    /* emphasis component: default = emphasis icon: muted */
    --zui-content-tab-image-item-interactive-item-default-enabled: var(--zui-color-gs-80);
    --zui-content-tab-image-item-interactive-item-default-hovered: var(--zui-color-gs-90);
    --zui-content-tab-image-item-interactive-item-default-pressed: var(--zui-color-gs-70);

    /* emphasis component: selected = emphasis icon: highlight */
    --zui-content-tab-image-item-interactive-item-selected-enabled: var(--zui-color-gs-10);
    --zui-content-tab-image-item-interactive-item-selected-hovered: var(--zui-color-gs-70);
    --zui-content-tab-image-item-interactive-item-selected-pressed: var(--zui-color-gs-60);

    /* step indicator item */
    --zui-step-indicator-item-text-color-next: var(--zui-color-gs-85);
    --zui-step-indicator-item-text-color-selected: var(--zui-color-gs-120);
    --zui-step-indicator-item-text-color-visited: var(--zui-color-gs-85);

    --zui-step-indicator-item-border-color-next: var(--zui-color-gs-80);
    --zui-step-indicator-item-border-color-selected: var(--zui-color-pb-100);
    --zui-step-indicator-item-border-color-visited: var(--zui-color-pb-70);

    /* focus view bar */
    --zui-focus-view-bar-background-color: var(--zui-color-gs-10);

    /* tag component */
    --zui-color-tag-background-enabled: var(--zui-color-gs-30);
    --zui-color-tag-background-hovered: var(--zui-color-gs-40);
    --zui-color-tag-background-active: var(--zui-color-gs-50);
    --zui-color-tag-border: var(--zui-color-gs-60);
    --zui-color-tag-text: var(--zui-color-gs-100);

    /* interactive text */
    --zui-color-interactive-text-default-enabled: var(--zui-color-gs-100);
    --zui-color-interactive-text-default-hovered: var(--zui-color-gs-70);
    --zui-color-interactive-text-default-focused: var(--zui-color-gs-100);
    --zui-color-interactive-text-default-pressed: var(--zui-color-gs-85);

    --zui-color-interactive-text-subtle-enabled: var(--zui-color-gs-85);
    --zui-color-interactive-text-subtle-hovered: var(--zui-color-gs-60);
    --zui-color-interactive-text-subtle-focused: var(--zui-color-gs-85);
    --zui-color-interactive-text-subtle-pressed: var(--zui-color-gs-70);

    --zui-color-interactive-text-highlight-enabled: var(--zui-color-gs-120);
    --zui-color-interactive-text-highlight-hovered: var(--zui-color-gs-80);
    --zui-color-interactive-text-highlight-focused: var(--zui-color-gs-120);
    --zui-color-interactive-text-highlight-pressed: var(--zui-color-gs-85);

    --zui-color-interactive-text-primary-highlight-enabled: var(--zui-color-pb-100);
    --zui-color-interactive-text-primary-highlight-hovered: var(--zui-color-pb-80);
    --zui-color-interactive-text-primary-highlight-focused: var(--zui-color-pb-100);
    --zui-color-interactive-text-primary-highlight-pressed: var(--zui-color-pb-90);

    /* time picker input */
    --zui-time-picker-input-border-bottom-disabled-color: rgba(var(--zui-color-gs-60-rgb), var(--zui-disabled-opacity));

    /* time picker cell */
    --zui-time-picker-cell-background-color: var(--zui-color-gs-30);
    --zui-time-picker-cell-background-color-hovered: var(--zui-color-gs-40);
    --zui-time-picker-cell-background-color-focused: var(--zui-color-gs-30);
    --zui-time-picker-cell-background-color-pressed: var(--zui-color-gs-50);

    --zui-time-picker-cell-border-color: var(--zui-color-gs-60);
    --zui-time-picker-cell-border-color-hovered: var(--zui-color-gs-60);
    --zui-time-picker-cell-border-color-focused: var(--zui-color-gs-60);
    --zui-time-picker-cell-border-color-pressed: var(--zui-color-gs-60);

    --zui-time-picker-cell-text-color: var(--zui-color-gs-120);
    --zui-time-picker-cell-text-color-hovered: var(--zui-color-gs-120);
    --zui-time-picker-cell-text-color-focused: var(--zui-color-gs-120);
    --zui-time-picker-cell-text-color-pressed: var(--zui-color-gs-120);

    /* time picker cell divider */
    --zui-time-picker-cell-divider-color: var(--zui-color-gs-100);

    /* content accordion details */
    /* background */
    --zui-content-accordion-details-background: var(--zui-color-gs-10);

    /* bottom line */
    --zui-content-accordion-details-bottom-line-enabled-disabled-hovered-pressed: var(--zui-color-gs-40);
    --zui-content-accordion-details-bottom-line-focused: var(--zui-color-gs-100);

    /* standalone for size s + m + l */
    /* closed */
    --zui-content-accordion-details-standalone-closed-text-icon-enabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-standalone-closed-text-icon-disabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-standalone-closed-text-icon-hovered: var(--zui-color-gs-60);
    --zui-content-accordion-details-standalone-closed-text-icon-focused: var(--zui-color-gs-85);
    --zui-content-accordion-details-standalone-closed-text-icon-pressed: var(--zui-color-gs-70);

    /* open */
    --zui-content-accordion-details-standalone-open-text-icon-enabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-standalone-open-text-icon-disabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-standalone-open-text-icon-hovered: var(--zui-color-gs-80);
    --zui-content-accordion-details-standalone-open-text-icon-focused: var(--zui-color-gs-120);
    --zui-content-accordion-details-standalone-open-text-icon-pressed: var(--zui-color-gs-85);

    /* integrated for size m + s */
    /* closed */
    --zui-content-accordion-details-integrated-s-m-closed-text-enabled: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-s-m-closed-text-disabled: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-s-m-closed-text-hovered: var(--zui-color-gs-70);
    --zui-content-accordion-details-integrated-s-m-closed-text-focused: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-s-m-closed-text-pressed: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-s-m-closed-icon-enabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-s-m-closed-icon-disabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-s-m-closed-icon-hovered: var(--zui-color-gs-60);
    --zui-content-accordion-details-integrated-s-m-closed-icon-focused: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-s-m-closed-icon-pressed: var(--zui-color-gs-70);
    /* open */
    --zui-content-accordion-details-integrated-s-m-open-text-icon-enabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-s-m-open-text-icon-disabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-s-m-open-text-icon-hovered: var(--zui-color-gs-80);
    --zui-content-accordion-details-integrated-s-m-open-text-icon-focused: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-s-m-open-text-icon-pressed: var(--zui-color-gs-85);

    /* integrated for size l */
    /* closed */
    --zui-content-accordion-details-integrated-l-closed-text-enabled: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-l-closed-text-disabled: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-l-closed-text-hovered: var(--zui-color-gs-80);
    --zui-content-accordion-details-integrated-l-closed-text-focused: var(--zui-color-gs-100);
    --zui-content-accordion-details-integrated-l-closed-text-pressed: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-l-closed-icon-enabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-l-closed-icon-disabled: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-l-closed-icon-hovered: var(--zui-color-gs-60);
    --zui-content-accordion-details-integrated-l-closed-icon-focused: var(--zui-color-gs-85);
    --zui-content-accordion-details-integrated-l-closed-icon-pressed: var(--zui-color-gs-70);
    /* open */
    --zui-content-accordion-details-integrated-l-open-text-icon-enabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-l-open-text-icon-disabled: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-l-open-text-icon-hovered: var(--zui-color-gs-80);
    --zui-content-accordion-details-integrated-l-open-text-icon-focused: var(--zui-color-gs-120);
    --zui-content-accordion-details-integrated-l-open-text-icon-pressed: var(--zui-color-gs-85);

    /* ~~ Expander ~~ */
    /* background */
    /* hierarchy first */
    --zui-color-expander-bg-first-hierarchy-enabled: var(--zui-color-gs-10);
    --zui-color-expander-bg-first-hierarchy-disabled: var(--zui-color-gs-10);
    --zui-color-expander-bg-first-hierarchy-hover: var(--zui-color-gs-20);
    --zui-color-expander-bg-first-hierarchy-focus: var(--zui-color-gs-10);
    --zui-color-expander-bg-first-hierarchy-active: var(--zui-color-gs-30);
    /* hierarchy second */
    --zui-color-expander-bg-second-hierarchy-enabled: var(--zui-color-gs-20);
    --zui-color-expander-bg-second-hierarchy-disabled: var(--zui-color-gs-20);
    --zui-color-expander-bg-second-hierarchy-hover: var(--zui-color-gs-30);
    --zui-color-expander-bg-second-hierarchy-focus: var(--zui-color-gs-20);
    --zui-color-expander-bg-second-hierarchy-active: var(--zui-color-gs-40);

    /* icon */
    --zui-color-expander-icon-color: var(--zui-color-gs-80);
  }
`;
const themeLight = r`
  ${commonStyles} ${colors}
`;
export { themeLight };