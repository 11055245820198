import { EventEmitter, Inject, Injectable } from '@angular/core';
import { SettingsServiceProvider, Theme, ThemeServiceProvider } from '@zeiss/ng-vis-common/types';
import { registerTheme, registerTypefaces, setAppTheme, themeBase } from '@zeiss/zui-theme-base';
import { themeDark } from '@zeiss/zui-theme-dark';
import { themeLight } from '@zeiss/zui-theme-light';
import { LogPre } from './theme';
import { SETTINGS_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { NgVisAuthSettings } from '@zeiss/ng-vis-vp-auth/types';

@Injectable()
export class ThemeService implements ThemeServiceProvider {
	private _theme: Theme = Theme.light;
	private _auto = false;

	ThemeChanged$ = new EventEmitter<Theme>();

	constructor(@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisAuthSettings>) {
		console.debug(LogPre, `User wants: ${this.settingsService.settings.SystemTheme}`);
		console.debug(LogPre, `OS setting: ${Theme[this.ThemeOs]}`);

		if (this.settingsService.settings.SystemTheme === 'os-setting' || !this.settingsService.settings.SystemTheme) {
			this.Auto = true;
		} else {
			this.Theme = Theme[this.settingsService.settings.SystemTheme as any] as any;
		}

		this.init();
	}

	private init() {
		registerTheme(themeBase);
		registerTheme(themeLight);
		registerTheme(themeDark);

		registerTypefaces('/fonts');

		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
			if (this.Auto) {
				console.debug(LogPre, `OS setting changed: ${Theme[this.ThemeOs]}`);
				this.Theme = this.ThemeOs;
			}
		});
	}

	get Theme(): Theme {
		return +this._theme;
	}

	set Theme(theme: Theme) {
		this._theme = theme;

		setAppTheme(Theme[this._theme]);
		this.ThemeChanged$.emit(+this._theme);
		console.debug(LogPre, `Set to: ${Theme[this._theme]}`);

		if (!this.Auto && this.settingsService.settings.SystemTheme !== (Theme[this._theme] as any)) {
			this.settingsService.settings = { SystemTheme: Theme[this._theme] as any };
		}
	}

	get ThemeOs(): Theme {
		return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.dark : Theme.light;
	}

	get Auto() {
		return this._auto;
	}

	set Auto(auto: boolean) {
		if (auto) {
			this._auto = auto;
			this.Theme = this.ThemeOs;
			if (this.settingsService.settings.SystemTheme !== 'os-setting') {
				this.settingsService.settings = { SystemTheme: 'os-setting' };
			}
		} else {
			this._auto = false;
		}
	}
}
