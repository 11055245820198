import '@zeiss/zui';

import { filter, lastValueFrom, mergeMap } from 'rxjs';
import { Build } from 'src/environments/build';
import { App } from 'src/environments/shared';

import { AfterViewInit, Component, HostListener, Inject, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LOGGER_SERVICE, SYSTEM_SERVICE, TASK_API_SERVICE, THEME_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { registerCustomIcons } from '@zeiss/ng-vis-common/operators';
import {
	LoggerServiceProvider,
	LogLevel,
	SystemServiceProvider,
	TaskApiServiceProvider,
	ThemeServiceProvider,
} from '@zeiss/ng-vis-common/types';
import { TutorialService } from '@zeiss/ng-vis-tutorial';
import { TutorialTopics } from '@zeiss/ng-vis-tutorial/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_SERVICE, ROUTER_SERVICE, RULE_PROTECT_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { AuthServiceProvider, RouterServiceProvider, RuleProtectServiceProvider } from '@zeiss/ng-vis-vp-auth/types';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL_SEARCH_DATA_PROVIDER, GlobalSearchDataProvider } from '@zeiss/ng-vis-common';
import { AccessFaqDialogComponent } from './common/access-faq-dialog/access-faq-dialog.component';
import { ClarityTrackingService } from './common/clarity-tracking/clarity-tracking.service';
import { LanguageService } from './common/language/language.service';
import { LogCat } from './common/logger/log-cat.enum';
import { buildRouteActions } from './common/search/vp-search.actions';

@Component({
	selector: 'vis-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	@HostListener('window:popstate')
	onPopState() {
		this.closeTutorial();
	}

	tutorialTopics = TutorialTopics;
	app = App;

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		public language: LanguageService,
		public tutorial: TutorialService,
		private clarityTrackingService: ClarityTrackingService,
		private renderer: Renderer2,
		@Inject(AUTH_SERVICE) public authService: AuthServiceProvider,
		@Inject(RULE_PROTECT_SERVICE) public ruleProtectService: RuleProtectServiceProvider,
		@Inject(LOGGER_SERVICE) private logger: LoggerServiceProvider,
		@Inject(SYSTEM_SERVICE) public systemService: SystemServiceProvider,
		@Inject(ROUTER_SERVICE) public routerService: RouterServiceProvider,
		@Inject(THEME_SERVICE) public themeService: ThemeServiceProvider,
		@Inject(TASK_API_SERVICE) public taskService: TaskApiServiceProvider,
		@Inject(GLOBAL_SEARCH_DATA_PROVIDER) private globalSearchDataService: GlobalSearchDataProvider,
		private dialog: MatDialog,
		private router: Router,
		private translateService: TranslateService
	) {
		this.clarityTrackingService.renderer = this.renderer;
		this.authService.signIn();
		this.language.initLanguageSettings();
		this.clarityTrackingService.initClarityTracking();

		this.ruleProtectService
			.isAuthorized$({ rule: RULE.system_functions, required: ['view_tasks'] })
			.pipe(
				filter((isAuthorized) => isAuthorized),
				mergeMap(() => this.taskService.getTasks({ showNewOnly: true, showBaseTasks: true, showNotifications: true }))
			)
			.subscribe();
	}

	ngAfterViewInit() {
		this.logger.Log(`${App.Name1}${App.Name2} ${Build.appVersion} entered`, {
			category: LogCat.app,
			level: LogLevel.debug,
		});

		registerCustomIcons(this.matIconRegistry, this.domSanitizer);
		this.initGlobalSearch();
	}

	closeTutorial() {
		this.tutorial?.Close();
	}

	async openFaqDialog(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		await lastValueFrom(this.dialog.open(AccessFaqDialogComponent).afterClosed());
	}

	/**
	 * Initializes the global search with the routes from the router configuration
	 * as options to directly navigate to.
	 */
	private initGlobalSearch = async () => {
		const config = this.router.config;
		const routes = config.map((route) => ({ path: route.path, title: route.data?.title }));
		const promises: Promise<{ path: string; title: string }>[] = [];
		for (const route of routes) {
			if (!route.title) continue;
			const promise = lastValueFrom(this.translateService.get(route.title)).then((title) => ({ ...route, title }));
			promises.push(promise);
		}
		const resolvedRoutes = await Promise.all(promises);
		this.globalSearchDataService.registerNewActions(buildRouteActions(this.router, resolvedRoutes));
	};
}
