import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { VpEnvironment } from '@zeiss/ng-vis-common/types';

import { AppModule } from './app/app.module';
import { Build } from './environments/build';
import { Environment } from './environments/environment';
import { App } from './environments/shared';

/**
 * During the ESBuild support of Angular they're on the roadmap to remove zone.js
 * This is in general a good thing as zone.js needs some polyfills to work properly.
 * Some of our libraries are still using internally things with zone.js this is why we need to make this "hacky" solution
 * To avoid the "global not defined" error.
 * As soon as Angular 18 is out and zone.js is deprecated we can remove this line.
 */
(window as any).global = window;

const logPre = '📱 [App]';
console.debug(logPre, 'entered', `${App.NameFull} ${Build.appVersion} ${Build.branch}`);

if (Environment.AppMeta.Environment === VpEnvironment.PRD) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() => console.debug(logPre, `bootstrapped in ${new Date().getTime() - App.AppEntered!.getTime()}ms`))
	.catch((err) => console.error(logPre, err));
