import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HeaderbarPortalService } from '@zeiss/ng-vis-common/components';
import {
	APP_NAME,
	AUTHORIZED_ROUTES_SERVICE,
	ENVIRONMENT,
	HEADERBAR_PORTAL_SERVICE,
	LOGGER_SERVICE,
	SYSTEM_SERVICE,
	THEME_SERVICE,
	TOAST_SERVICE,
} from '@zeiss/ng-vis-common/injection-tokens';
import { SystemService, ToastService } from '@zeiss/ng-vis-common/services';
import { VpRuleProtectGuard } from '@zeiss/ng-vis-vp-auth/guards';
import { AUTH_GUARD, AUTH_SERVICE, ROUTER_SERVICE, RULE_PROTECT_SERVICE } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { LoggingCorrelationInterceptor, VpBackendAuthHeaderInterceptor } from '@zeiss/ng-vis-vp-auth/interceptors';
import {
	AuthorizedRoutesService,
	VpAuthService,
	VpRouterService,
	VpRuleProtectService,
} from '@zeiss/ng-vis-vp-auth/services';
import { Environment } from 'src/environments/environment';
import { App } from '../../environments/shared';
import { LoggerService } from '../common/logger/logger.service';
import { ThemeService } from '../common/theme/theme.service';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
	providers: [
		{ provide: ENVIRONMENT, useValue: Environment.AppMeta.Environment },
		{ provide: APP_NAME, useValue: { shortName: App.Name1[0] + App.Name2[0], fullName: App.NameFull } },
		{ provide: LOGGER_SERVICE, useClass: LoggerService },
		{ provide: THEME_SERVICE, useClass: ThemeService },
		{ provide: AUTH_GUARD, useClass: VpRuleProtectGuard },
		{ provide: AUTH_SERVICE, useClass: VpAuthService },
		{ provide: RULE_PROTECT_SERVICE, useClass: VpRuleProtectService },
		{ provide: AUTHORIZED_ROUTES_SERVICE, useClass: AuthorizedRoutesService },
		{ provide: ROUTER_SERVICE, useClass: VpRouterService },
		{ provide: HEADERBAR_PORTAL_SERVICE, useClass: HeaderbarPortalService },
		{ provide: TOAST_SERVICE, useClass: ToastService },
		{ provide: SYSTEM_SERVICE, useClass: SystemService },
		{ provide: HTTP_INTERCEPTORS, useClass: VpBackendAuthHeaderInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoggingCorrelationInterceptor, multi: true },
	],
})
export class SharedInjectionsModule {}
