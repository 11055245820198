export default {
	signIn: 'Sign in',
	home: 'Home',
	tutorial: {
		app: {
			welcome: 'Welcome',
			welcomeDesc:
				'Welcome to {{ appName }}!<br><br>Here is a tutorial to <b>guide you through this application</b>. You can easily close it by tapping anywhere outside.',
		},
		bookmark: {
			add: 'Add a bookmark',
			addSpecific: 'Add {{ titlePrefix }} - {{ title }} to your bookmarks',
			addSpecificByClick:
				"Add <i>{{ titlePrefix }} - {{ title }}</i> to your bookmarks by clicking this icon.<br><br>Bookmarks are getting <b>pinned to the start page</b>.<br>You'll get to the start page by clicking the <b>app logo</b> at the top left.",
			all: 'All bookmarks',
			bookmarks: 'Bookmarks',
			pinnedSpecific: 'Pinned <b>{{ url }}</b> to the start page',
			removeSpecific: 'Remove {{ titlePrefix }} - {{ title }} from your bookmarks',
			sectionDescription:
				'These are your bookmarks.<br><br>Remove bookmarks by clicking the <b>icon at the top right</b>.',
		},
	},
	warnings: {
		languageNotSupported:
			'This area does not support your preferred language ({{ currentLanguage }}). Instead, the default language ({{ defaultLanguage }}) is being used',
		sessionTimedOut: 'Session timed out',
		sessionTimedOutDesc: 'Your session has timed out. Please sign in again.',
	},
	accessHelp: 'Need help with access?',
	proceed: 'Proceed',
	cancel: 'Cancel',
};
