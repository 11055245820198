import { HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { LogPreRouter } from '@zeiss/ng-vis-common/types';

import { GetResolvedUrl, StoredRoute } from './route';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
	private storedRoutes = new Map<string, StoredRoute>();

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		if (!!route.routeConfig?.data?.cache) {
			console.debug(LogPreRouter, 'Caching route:', GetResolvedUrl(route));
		}
		return !!route.routeConfig?.data?.cache;
	}

	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		const path = GetResolvedUrl(route);
		this.storedRoutes.set(path, {
			handle,
			queryParams: route.queryParams ?? new HttpParams(),
		});

		if (handle && (handle as any).componentRef.instance.visAfterDetach) {
			(handle as any).componentRef.instance.visAfterDetach();
		}
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		const path = GetResolvedUrl(route);
		if (!!this.storedRoutes.get(path)) {
			console.debug(LogPreRouter, 'Restoring cached route:', GetResolvedUrl(route));
		}
		return !!this.storedRoutes.get(path);
	}

	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		const path = GetResolvedUrl(route);
		const storedRoute = this.storedRoutes.get(path);
		if (!storedRoute) {
			return null;
		}

		if ((storedRoute.handle as any).componentRef.instance.visAfterReAttach) {
			setTimeout(
				() => (storedRoute.handle as any).componentRef.instance.visAfterReAttach(storedRoute.queryParams),
				100
			);
		}
		return storedRoute.handle;
	}

	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return future.routeConfig === curr.routeConfig;
	}
}
